import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'typeface-roboto';
import { Switch, Route, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

import Dashboard from '../Views/Dashboard';
import Schedules from '../Views/Schedules';
import CarInspections from '../Views/CarInspections';
import Expirings from '../Views/Expirings';
import Billing from '../Views/Billing';
import MessageTemplates from '../Views/MessageTemplates';
import Companies from '../Views/Companies';
import CompanyHistory from '../Views/CompanyHistory';
import Map from '../Views/Map';
import Contact from '../Views/Contact';
import MyAccount from '../Views/MyAccount';
import MyUsers from '../Views/MyUsers';
import ChangePassword from '../Views/ChangePassword';

const useStyles = makeStyles(theme => ({
  main: {
    margin: theme.spacing(7, 1, 11, 1)
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide {...props} direction='up' />;
});

export default function RouterView(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const user = props.user;
  const [entity, setEntity] = useState(null); // entity to be deleted on server
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('xs'));

  const handleDelete = () => {
    ajax(entity.op, {
      id: entity.id
    })
      .then(response => {
        if (response.ok) {
          entity.history.replace('/Reload');
          setTimeout(() => {
            entity.history.replace(entity.pathname);
            setEntity(null);
          });
        }
      });
  };

  return (
    <Fragment>
      <div className={classes.main}>
        <Switch>
          <Route exact path='/' render={() => <Redirect to='/Dashboard' />}></Route>
          <Route exact path='/Reload'                    render={(props) => null}></Route>
          <Route exact path='/Dashboard'                 render={(props) => <Dashboard        {...props} ajax={ajax}                                                        />}></Route>
          <Route exact path='/Schedules/'                render={(props) => <Schedules        {...props} ajax={ajax}             deleteEntity={entity => setEntity(entity)} />}></Route>
          <Route exact path='/CarInspections/:op?'       render={(props) => <CarInspections   {...props} ajax={ajax}             deleteEntity={entity => setEntity(entity)} />}></Route>
          <Route exact path='/Expirings'                 render={(props) => <Expirings        {...props} ajax={ajax}                                                        />}></Route>
          <Route exact path='/Billing'                   render={(props) => <Billing          {...props} ajax={ajax}                                                        />}></Route>
          <Route exact path='/MessageTemplates'          render={(props) => <MessageTemplates {...props} ajax={ajax}             deleteEntity={entity => setEntity(entity)} />}></Route>
          <Route exact path='/Companies'                 render={(props) => <Companies        {...props} ajax={ajax} user={user} deleteEntity={entity => setEntity(entity)} />}></Route>
          <Route exact path='/CompanyHistory/:idCompany' render={(props) => <CompanyHistory   {...props} ajax={ajax}                                                        />}></Route>
          <Route exact path='/Map/:county'               render={(props) => <Map              {...props} ajax={ajax} user={user} deleteEntity={entity => setEntity(entity)} />}></Route>
          <Route exact path='/Contact'                   render={(props) => <Contact          {...props} ajax={ajax}                                                        />}></Route>
          <Route exact path='/MyAccount'                 render={(props) => <MyAccount        {...props} ajax={ajax}                                                        />}></Route>
          <Route exact path='/MyUsers'                   render={(props) => <MyUsers          {...props} ajax={ajax}             deleteEntity={entity => setEntity(entity)} />}></Route>
          <Route exact path='/ChangePassword'            render={(props) => <ChangePassword   {...props} ajax={ajax}                                                        />}></Route>
          <Route render={() => <Redirect to='/' />}></Route>
        </Switch>
      </div>

      {entity &&
        <Dialog scroll='paper' TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={entity !== null}
          onClose={() => setEntity(null)}
        >
          <DialogTitle>{entity.title}</DialogTitle>
          <DialogContent>
            <DialogContentText><Typography component='span'>{entity.question}</Typography></DialogContentText>
            <DialogContentText><Typography component='span'>{entity.content}</Typography></DialogContentText>
            <DialogContentText>
              <Typography component='span'>Se va sterge: </Typography>
              <Typography component='span' color='secondary'>{entity.entity}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus
              onClick={() => setEntity(null)}
            >
              Anuleaza
            </Button>
            <Button color='secondary'
              onClick={handleDelete}
            >
              Sterge
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Fragment>
  );
}
RouterView.propTypes = {
  ajax: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};
RouterView.defaultProps = {
  isLoading: false
};