import React, {useState} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  }
}));

export default function Login(props) {
  const classes = useStyles();
  const [value, setValue] = useState({
    username: '',
    password: ''
  });

  const handleValueChange = e => {
    setValue({...value, [e.target.name]: e.target.value});
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter')
      submitValue();
  };

  const submitValue = async () => {
    props.ajax('Users::Login', value)
      .then(response => {
        if (response.ok) {
          localStorage.setObject('user', response.data);
          props.setUser(response.data);
        }
      });
  };

  return (
    <Container fixed maxWidth='xs'>
      <CardMedia image='/appLogo_500.png' style={{height: '0px', paddingTop: `${170 / 500 * 100}%`}} />
      <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 64}} disabled={props.isLoading}
        name='username'
        label='Nume de utilizator'
        value={value.username}
        onKeyPress={handleKeyPress}
        onChange={handleValueChange}
      />
      <TextField fullWidth size='small' variant='outlined' margin='dense' required type='password' disabled={props.isLoading}
        name='password'
        label='Parola'
        value={value.password}
        onKeyPress={handleKeyPress}
        onChange={handleValueChange}
      />
      <Button fullWidth size='small' variant='contained' color='primary' disabled={props.isLoading || value.username.length < 5}
        onClick={submitValue}
      >
        Autentificare
      </Button>
      <Box mt={3} style={{display: 'flex', justifyContent: 'space-between'}}>
        <Link to='/ResetPassword' className={classes.link}>
          <Button size='small' color='secondary' disabled={props.isLoading}>
            Am uitat parola
          </Button>
        </Link>
        <Link to='/Signup' className={classes.link}>
          <Button size='small' disabled={props.isLoading}>
            Creaza cont
          </Button>
        </Link>
      </Box>
    </Container>
  );
}