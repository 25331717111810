import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Fab from '@material-ui/core/Fab';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function MessageTemplates(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const defaultValue = {
    id: '',
    delay: '',
    messageTemplate: ''
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, add, edit
    value: null
  });
  const [messageTemplates, setMessageTemplates] = useState([]);

  useEffect(() => {
    if (props.match.params.op) {
      setView({mode: 'add', value: defaultValue});
      setIsLoaded(true);
    }
    ajax('MessageTemplates::Search')
      .then(response => {
        if (response.ok) {
          setMessageTemplates(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value}});
  };

  const handleSave = async () => {
    ajax('MessageTemplates::Save', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('MessageTemplates::Search')
            .then(response => {
              if (response.ok) {
                setMessageTemplates(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Sabloane SMS-uri</Typography>
      <Typography color='textSecondary'>Cu ajutorul sabloanelor va puteti personaliza interactiunea cu clientii</Typography>
      <Divider className={classes.divider} />

      {isLoaded && view.mode === 'initial' && messageTemplates.length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Folositi butonul din dreapta jos pentru a adauga un element.</Typography>
        </Fragment>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 2}}
              error={!Boolean(view.value.delay.match(/^[0-9]{1,2}$/))}
              name='delay'
              label='Cu cate zile inainte se trimite SMS-ul'
              placeholder='Cu cate zile inainte se trimite SMS-ul'
              value={view.value.delay}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required multiline rows={6}
              error={view.value.messageTemplate.length < 20}
              name='messageTemplate'
              label='Sablon mesaj'
              placeholder='Sablon mesaj'
              value={view.value.messageTemplate}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color='primary'>{`Lungime estimata a mesajului: ${view.value.messageTemplate.length - (!!~view.value.messageTemplate.indexOf('%EXPIRA_LA%') ? 1 : 0) - (!!~view.value.messageTemplate.indexOf('%NUMAR_AUTO%') ? 5 : 0)} caractere.`}</Typography>
            <Typography component='div' align='justify' style={{marginTop: '16px'}}>
              <Typography color='textSecondary' display='inline'>Se pot folosi urmatoarele expresii pentru generarea SMS-urilor folosind informatii din baza de date: </Typography>
              <Typography color='textPrimary' display='inline'>%NUMAR_AUTO%</Typography>
              <Typography color='textSecondary' display='inline'> si </Typography>
              <Typography color='textPrimary' display='inline'>%EXPIRA_LA%</Typography>
              <Typography color='textSecondary' display='inline'>.</Typography>
            </Typography>
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {messageTemplates.map(messageTemplate =>
            <ListItem key={messageTemplate.id} dense button divider
              onClick={() => setView({mode: 'edit', value: messageTemplate})}
            >
              <ListItemText secondaryTypographyProps={{style: {whiteSpace: 'pre-wrap'}}}
                primary={`Se trimite cu ${messageTemplate.delay} zile inainte de expirare`}
                secondary={messageTemplate.messageTemplate}
              />
              <ListItemSecondaryAction>
                <IconButton edge='end'
                  onClick={() => props.deleteEntity({
                    title: 'Stergere sablon SMS',
                    question: 'Sunteti sigur ca doriti stergerea acestui sablon?',
                    content: 'Dupa aceasta operatiune, nu se vor mai trimite SMS-uri care respecta regulile acestuia.',
                    entity: messageTemplate.messageTemplate,
                    op: 'MessageTemplates::Delete',
                    id: messageTemplate.id,
                    history: props.history,
                    pathname: props.location.pathname
                  })}
                >
                  <DeleteIcon color='secondary' />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      }

      <Typography variant='caption' color='secondary' component='div' align='justify' style={{marginTop: '16px'}}>Atentie, conform standardului, un SMS are lungimea maxima de 160 de caractere! Orice depasire a lungimii unui SMS trimis de catre dumneavoastra, atrage facturarea acestora ca SMS-uri separate.</Typography>
      <Typography variant='caption' color='secondary' component='div' align='justify' style={{marginTop: '16px'}}>De asemenea, orice caracter care nu face parte din setul de caractere standard SMS, are lungimea a doua caractere standard. Astfel, de exemplu, diacriticele si anumite caractere speciale ocupa un spatiu de doua caractere intr-un SMS.</Typography>
      <Typography variant='caption' component='div' style={{marginTop: '16px'}}>
        <Link href='https://seeme.hu/bine-de-stiut/specificatii-solutie/caractere-si-lungimea-mesajului-in-sms' target='_blank' rel='noreferrer'>Mai multe detalii aici</Link>
      </Typography>

      {isLoaded && view.mode === 'initial' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'add', value: defaultValue})}
        >
          <AddIcon />
        </Fab>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            !Boolean(view.value.delay.match(/^[0-9]{1,2}$/)) ||
            view.value.messageTemplate.length < 20
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}