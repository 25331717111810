import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function CarInspections(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const defaultValue = {
    id: '',
    plate: '',
    phone: '',
    validity: '12',
    validTo: `${new Date().getFullYear() + 1}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, add, edit
    value: null
  });
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (props.match.params.op) {
      setView({mode: 'add', value: defaultValue});
      setIsLoaded(true);
    }
    ajax('CarInspections::GetLast')
      .then(response => {
        if (response.ok) {
          setData(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterData = item => {
    return false || (
        !!~`${item.plate} ${item.phone}`.toLowerCase().indexOf(filter.toLowerCase())
    );
  };

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value.toUpperCase()}});
  };

  const handleValidityChange = e => {
    var date = new Date().addMonths(e.target.value);
    date = `${date.getFullYear() + 1}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    setView({...view, value: {...view.value, validity: e.target.value, validTo: date}});
  };

  const handleSave = async () => {
    ajax('CarInspections::Save', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('CarInspections::GetLast')
            .then(response => {
              if (response.ok) {
                setData(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Inspectii tehnice periodice</Typography>
      <Typography color='textSecondary'>Administrare inspectii tehnice periodice efectuate</Typography>
      <Divider className={classes.divider} />

      {isLoaded && data.length > 0 && view.mode === 'initial' &&
        <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      }

      {isLoaded && view.mode === 'initial' && data.length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Folositi butonul din dreapta jos pentru a adauga un element.</Typography>
        </Fragment>
      }

      {isLoaded && view.mode === 'initial' && data.length > 0 && data.filter(filterData).length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Filtrele aplicate de dumneavoastra nu au nici un rezultat.</Typography>
        </Fragment>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 7}}
              error={!Boolean(view.value.plate.match(/^[A-Z]{1,2}[0-9]{2,3}[A-Z]{3}$/))}
              name='plate'
              label='Numar de inmatriculare'
              placeholder='Numar de inmatriculare'
              value={view.value.plate}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 10}}
              error={!Boolean(view.value.phone.match(/^07[0-9]{8}$/))}
              name='phone'
              label='Numar de telefon'
              placeholder='Numar de telefon'
              value={view.value.phone}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required select
              name='validity'
              label='Valabilitate ITP'
              placeholder='Valabilitate ITP'
              value={view.value.validity}
              onChange={handleValidityChange}
            >
              <MenuItem value='6'>6 luni</MenuItem>
              <MenuItem value='12'>1 an</MenuItem>
              <MenuItem value='24'>2 ani</MenuItem>
              <MenuItem value='36'>3 ani</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' type='date' required
              name='validTo'
              label='ITP-ul expira la data'
              placeholder='ITP-ul expira la data'
              value={view.value.validTo}
              onChange={handleValueChange}
            />
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {data.filter(filterData).map(item =>
            <ListItem key={item.id} dense button divider
              onClick={() => setView({mode: 'edit', value: item})}
            >
              <ListItemText disableTypography
                primary={item.plate}
                secondary={
                  <Fragment>
                    <Typography variant='body2' color='textSecondary'>{`${item.phone}, expira pe ${item.validTo}`}</Typography>
                    <Typography variant='body2' color='textSecondary'>{`Adaugat de ${item.createdBy} la data de ${item.createdAt}`}</Typography>
                  </Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge='end'
                  onClick={() => props.deleteEntity({
                    title: 'Stergere ITP',
                    question: 'Sunteti sigur ca doriti stergerea acestei inspectii?',
                    content: 'Dupa aceasta operatiune, nu se vor mai trimite SMS-uri catre acest client.',
                    entity: item.plate,
                    op: 'CarInspections::Delete',
                    id: item.id,
                    history: props.history,
                    pathname: props.location.pathname
                  })}
                >
                  <DeleteIcon color='secondary' />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      }

      {isLoaded && view.mode === 'initial' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'add', value: defaultValue})}
        >
          <AddIcon />
        </Fab>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            !Boolean(view.value.plate.match(/^[A-Z]{1,2}[0-9]{2,3}[A-Z]{3}$/)) ||
            !Boolean(view.value.phone.match(/^07[0-9]{8}$/))
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}