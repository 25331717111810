import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import './Utils';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AnchorLink from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';

import Alert from '@material-ui/lab/Alert';

import DashboardIcon from '@material-ui/icons/Dashboard';
// import ScheduleIcon from '@material-ui/icons/Schedule';
import GroupIcon from '@material-ui/icons/Group';
import AlarmIcon from '@material-ui/icons/Alarm';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';

import Signup from './Views/Signup';
import ResetPassword from './Views/ResetPassword';
import Login from './Views/Login';
import TandC from './Views/TandC';
import RouterView from './Components/RouterView';

const theme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: '#31A3DD',
  //     contrastText: 'rgba(255, 255, 255, 0.87)'
  //   },
  //   secondary: {
  //     main: 'rgb(208, 65, 0)',
  //     contrastText: 'rgba(255, 255, 255, 0.87)'
  //   }
  // }
});

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  list: {
    width: '250px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

export default function App(props) {
  const classes = useStyles();
  // const reCAPTCHA_siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // test key provided by Google for localhost
  const reCAPTCHA_siteKey = '6Len4PMUAAAAAM7pKkTwKVzYn3HWbX0ZDPt-A5_Z'; // *.itpauto.eu key
  // const reCAPTCHA_secretKey = '6Len4PMUAAAAAPwKlgKMI43KSR6Z19DJCGaSbg4F';
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // used for ajax calls
  const [message, setMessage] = useState({
    ok: null,
    message: null
  });
  const menus = [
    { caption: 'Dashboard', linkTo: '/Dashboard', icon: <DashboardIcon /> },
    // { caption: 'Programari', linkTo: '/Schedules', icon: <ScheduleIcon /> },
    { caption: 'Portofoliu ITP-uri', linkTo: '/CarInspections', icon: <GroupIcon /> },
    { caption: 'Expirari', linkTo: '/Expirings', icon: <AlarmIcon /> },
    { caption: 'Financiar', linkTo: '/Billing', icon: <EuroSymbolIcon /> },
    { caption: 'Sabloane SMS-uri', linkTo: '/MessageTemplates', icon: <TextsmsIcon /> },
    { caption: 'Statii ITP', linkTo: '/Companies', icon: <EmojiTransportationIcon />, isAdmin: true },
    { caption: 'Contact', linkTo: '/Contact', icon: <ContactPhoneIcon /> },
    { caption: 'Contul meu', linkTo: '/MyAccount', icon: <AccountCircleIcon /> }
  ];

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    if (localStorage.getObject('user')) {
      ajax('Users::Login', {
        username: localStorage.getObject('user').username
      })
        .then(response => {
          if (response.ok) {
            localStorage.setObject('user', response.data);
            setUser(response.data);
          }
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ajax = async (op, data = {}) => {
    setIsLoading(true);
    const sid = localStorage.getObject('user') ? localStorage.getObject('user').sid : null;
    var formData = new FormData();
    formData.append('params', JSON.stringify(data));
    return await fetch(`${props.apiUrl}?ajax=${op}&sid=${sid}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        setMessage({
          ok: data.ok,
          message: data.message
        });
        return data;
      })
      .catch(error => {
        setIsLoading(false);
        setMessage({
          ok: false,
          message: 'Comunicarea cu serverul a esuat'
        });
        console.error('Eroare: Comunicarea cu serverul a esuat cu urmatorul mesaj:\\n', error);
      });
  };

  const toggleDrawer = (open) => e => {
    if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift'))
      return;

    setMenuOpen(open);
  };

  const handleError = (errorMessage) => {
    setMessage({
      ok: false,
      message: errorMessage
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  return (
    <Fragment>
      <CssBaseline />
      <Container fixed disableGutters={true} maxWidth='sm'>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {user
              ? <Fragment>
                  <AppBar position='fixed'>
                    <Toolbar variant='dense'>
                      <IconButton edge='start' className={classes.menuButton} color='inherit' onClick={toggleDrawer(true)}>
                        <MenuIcon />
                      </IconButton>
                      <Box className={classes.title}>
                        <Typography variant='h6'>Fidelizare ITP auto</Typography>
                      </Box>
                      {/* <Link to='/ShoppingCart' className={classes.link}>
                        <IconButton edge='end' color='inherit'>
                          <ShoppingCartIcon />
                        </IconButton>
                      </Link> */}
                    </Toolbar>
                  </AppBar>

                  <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS}
                    open={menuOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                  >
                    <div className={classes.list}
                      onClick={toggleDrawer(false)}
                      onKeyDown={toggleDrawer(false)}
                    >
                      <List>
                        {menus.filter(menu => !menu.isAdmin || (menu.isAdmin && user.idCompany === '1')).map((menu, i) =>
                          <Link key={i} to={menu.linkTo} className={classes.link}>
                            <ListItem button divider={i === menus.length - 1}>
                              <ListItemIcon>{menu.icon}</ListItemIcon>
                              <ListItemText primary={menu.caption} />
                            </ListItem>
                          </Link>
                        )}
                        <ListItem button divider onClick={handleLogout}>
                          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                          <ListItemText primary='Deconectare' />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Fragment>
                                <Typography variant='body2' align='center'>Copyright &copy; {new Date().getFullYear() !== 2020 ? `2020-${new Date().getFullYear()}` : `2020`}</Typography>
                                <Typography variant='body2' align='center'>Integrated Software SRL</Typography>
                              </Fragment>
                            }
                          />
                        </ListItem>
                      </List>
                    </div>
                  </SwipeableDrawer>

                  {Boolean(parseInt(user.agreement))
                    ? <RouterView ajax={ajax} isLoading={isLoading} error={handleError} user={user} />
                    : <TandC {...props} ajax={ajax} isLoading={isLoading} setUser={setUser} />
                  }
                </Fragment>
              : <Fragment>
                  <Switch>
                    <Route exact path='/Signup' render={(props) => <Signup {...props} ajax={ajax} isLoading={isLoading} reCAPTCHA_siteKey={reCAPTCHA_siteKey} setUser={setUser} />}></Route>
                    <Route exact path='/ResetPassword' render={(props) => <ResetPassword {...props} ajax={ajax} isLoading={isLoading} reCAPTCHA_siteKey={reCAPTCHA_siteKey} setUser={setUser} />}></Route>
                    <Route exact path='/Login' render={(props) => <Login {...props} ajax={ajax} isLoading={isLoading} setUser={setUser} />}></Route>
                    <Route render={(props) => <Login {...props} ajax={ajax} isLoading={isLoading} setUser={setUser} />}></Route>
                  </Switch>
                  <Box mt={3} ml={2} mr={2} mb={1}>
                    <Grid container>
                      <Grid item xs={12}><Divider /></Grid>
                      <Grid item xs={12} sm={2}><Typography variant='subtitle2'>Contact: </Typography></Grid>
                      <Grid item xs={12} sm={5}><Typography variant='body2'>Email: <AnchorLink href='mailto:itpauto.eu@gmail.com'>itpauto.eu@gmail.com</AnchorLink></Typography></Grid>
                      <Grid item xs={12} sm={5}><Typography variant='body2'>Telefon: 0770.040.011</Typography></Grid>
                      <Grid item xs={12}><Divider /></Grid>
                      <Grid item xs={12}><Typography variant='body2' align='center'>Copyright &copy; {new Date().getFullYear() !== 2020 ? `2020-${new Date().getFullYear()}` : `2020`} Integrated Software SRL</Typography></Grid>
                    </Grid>
                  </Box>
                </Fragment>
            }

            <Snackbar open={isLoading}>
              <LinearProgress color='secondary' style={{width: '100%'}} />
            </Snackbar>

            <Snackbar autoHideDuration={6000} style={{bottom: '80px', minWidth: '320px'}}
              open={message.ok !== null && message.message !== ''}
              onClose={() => setMessage({ok: null, message: null})}
            >
              <Alert elevation={6} variant='filled'
                severity={message.ok ? 'success' : 'error'} // error | info | success | warning
                onClose={() => setMessage({ok: null, message: null})}
              >
                {message.message}
              </Alert>
            </Snackbar>
          </BrowserRouter>
        </ThemeProvider>
      </Container>
    </Fragment>
  );
}