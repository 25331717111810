import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  }
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState([]);

  useEffect(() => {
    ajax('Dashboard::Details')
      .then(response => {
        if (response.ok) {
          setValue(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Dashboard</Typography>
      <Typography color='textSecondary'>Vedere de ansamblu</Typography>
      <Divider className={classes.divider} />

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded &&
        <List>
          <Link to='/Expirings' className={classes.link}>
            <ListItem dense divider>
              <ListItemText
                primary={`ITP-uri care expirara astazi: ${value.todayExpiringsCount}`}
                secondary='Vezi lista completa'
              />
            </ListItem>
          </Link>
          <Link to='/CarInspections' className={classes.link}>
            <ListItem dense divider>
              <ListItemText
                primary={`ITP-uri adaugate astazi: ${value.todayCarInspectionsCount}`}
                secondary='Vezi ultimele ITP-uri adaugate'
              />
            </ListItem>
          </Link>
          <Link to='/Expirings' className={classes.link}>
            <ListItem dense divider>
              <ListItemText
                primary={`SMS-uri trimise astazi: ${value.todayMessagesCount}`}
                secondary='Vezi ultimele SMS-uri trimise'
              />
            </ListItem>
          </Link>
          <Link to='/Billing' className={classes.link}>
            <ListItem dense divider>
              <ListItemText
                primary={`Balanta lunara: ${value.ballance} €`}
                secondary='Vezi detaliile financiare'
              />
            </ListItem>
          </Link>
          <Link to='/MessageTemplates' className={classes.link}>
            <ListItem dense divider>
              <ListItemText secondaryTypographyProps={{color: Boolean(parseInt(value.messageTemplatesCount)) ? 'textSecondary' : 'secondary'}}
                primary={`Sabloane SMS-uri: ${value.messageTemplatesCount}`}
                secondary={Boolean(parseInt(value.messageTemplatesCount)) ? 'Administreaza sabloanele SMS' : 'Nu aveti definit nici un sablon'}
              />
            </ListItem>
          </Link>
          <ListItem dense divider>
            <Typography variant='h6'>Istoric clienti</Typography>
          </ListItem>
          {value.lastSignups.map(item =>
            <ListItem key={item.id} dense divider>
              <ListItemText disableTypography
                primary={`${item.rarCode} - ${item.name}`}
                secondary={
                  <Fragment>
                    <Typography variant='body2' color={Boolean(parseInt(item.isBillable)) ? 'primary' : 'secondary'}>{`Tip cont: ${Boolean(parseInt(item.isBillable)) ? 'Activ' : 'Demo'}`}</Typography>
                    <Typography variant='body2' color='textSecondary'>{`Activ de la ${item.createdAt} (agent: ${item.referral})`}</Typography>
                  </Fragment>
                }
              />
            </ListItem>
          )}
        </List>
      }
    </Container>
  );
}