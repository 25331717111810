import React, {useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AnchorLink from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function Contact(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState({
    message: ''
  });

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Contact</Typography>
      <Typography color='textSecondary'>Ne puteti contacta prin oricare dintre metodele prezentate mai jos</Typography>
      <Divider className={classes.divider} />
      <CardMedia image='/appLogo_500.png' style={{height: '0px', paddingTop: `${170 / 500 * 100}%`}} />
      <Divider className={classes.divider} />

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded &&
        <List>
          <ListItem dense divider>
            <ListItemText
              primary='Email'
              secondary={<AnchorLink href='mailto:itpauto.eu@gmail.com'>itpauto.eu@gmail.com</AnchorLink>}
            />
          </ListItem>
          <ListItem dense divider>
            <ListItemText
              primary='Telefon'
              secondary='0770.040.011'
            />
          </ListItem>
        </List>
      }
      <Typography variant='body2' align='center'>Copyright &copy; {new Date().getFullYear() !== 2020 ? `2020-${new Date().getFullYear()}` : `2020`} Integrated Software SRL</Typography>
    </Container>
  );
}