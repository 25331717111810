import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function Schedules(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const defaultValue = {
    id: '',
    plate: '',
    phone: '',
    date: `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`,
    time: '09:00'
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, add, edit
    value: null
  });
  const [schedules, setSchedules] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (props.match.params.op) {
      setView({mode: 'add', value: defaultValue});
      setIsLoaded(true);
    }
    ajax('Schedules::Search')
      .then(response => {
        if (response.ok) {
          setSchedules(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterSchedules = schedule => {
    return false || (
        !!~`${schedule.plate} ${schedule.phone}`.toLowerCase().indexOf(filter.toLowerCase())
    );
  };

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value.toUpperCase()}});
  };

  const handleSave = async () => {
    ajax('Schedules::Save', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('Schedules::GetLast')
            .then(response => {
              if (response.ok) {
                setSchedules(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Programari</Typography>
      <Typography color='textSecondary'>Administrare programari ITP</Typography>
      <Divider className={classes.divider} />

      {isLoaded && schedules.length > 0 && view.mode === 'initial' &&
        <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      }

      {isLoaded && view.mode === 'initial' && schedules.length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Folositi butonul din dreapta jos pentru a adauga un element.</Typography>
        </Fragment>
      }

      {isLoaded && view.mode === 'initial' && schedules.length > 0 && schedules.filter(filterSchedules).length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Filtrele aplicate de dumneavoastra nu au nici un rezultat.</Typography>
        </Fragment>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required={true} inputProps={{maxLength: 7}}
                name='plate'
                label='Numar de inmatriculare'
                placeholder='Numar de inmatriculare'
                error={!Boolean(view.value.plate.match(/^[a-z]{1,2}[0-9]{2,3}[a-z]{3}$/i))}
                value={view.value.plate}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required={true} inputProps={{maxLength: 10}}
                name='phone'
                label='Numar de telefon'
                placeholder='Numar de telefon'
                error={!Boolean(view.value.phone.match(/^07[0-9]{8}$/i))}
                value={view.value.phone}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' type='date' required={true}
                name='date'
                label='Programare la data de'
                placeholder='Programare la data de'
                value={view.value.date}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' type='time' required={true}
                name='time'
                label='Programare la ora'
                placeholder='Programare la ora'
                value={view.value.time}
                onChange={handleValueChange}
              />
            </Grid>
            <Grid item xs={12}>

            </Grid>
          </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {schedules.filter(filterSchedules).map(schedule =>
            <ListItem key={schedule.id} dense button divider
              onClick={() => setView({mode: 'edit', value: schedule})}
            >
              <ListItemText
                primary={schedule.plate}
                secondary={`${schedule.phone} - programare pentru ${schedule.date} ora ${schedule.time}`}
              />
              <ListItemSecondaryAction>
                <IconButton edge='end'
                  onClick={() => props.deleteEntity({
                    title: 'Stergere programare',
                    question: 'Sunteti sigur ca doriti stergerea acestei programari?',
                    content: 'Dupa aceasta operatiune, nu veti mai avea acces la aceasta programare.',
                    entity: schedule.plate,
                    op: 'Schedules::Delete',
                    id: schedule.id,
                    history: props.history,
                    pathname: props.location.pathname
                  })}
                >
                  <DeleteIcon color='secondary' />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      }

      {isLoaded && view.mode === 'initial' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'add', value: defaultValue})}
        >
          <AddIcon />
        </Fab>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={!Boolean(view.value.plate.match(/^[a-z]{1,2}[0-9]{2,3}[a-z]{3}$/i)) || !Boolean(view.value.phone.match(/^07[0-9]{8}$/i))}
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}