import React, {Fragment, useState, useRef, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import GoogleMapReact from 'google-map-react';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import SearchIcon from '@material-ui/icons/Search';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

const AnyReactComponent = () => <LocationOnIcon color='secondary' fontSize='large' style={{width: '40px', transform: 'translate(-50%, -100%)'}} />;

export default function Map(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const user = props.user;
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, edit
    value: null
  });
  const [companies, setCompanies] = useState([]);
  const [myPosition, setMyPosition] = useState(null);
  const [filter, setFilter] = useState('');

  const googleMapsEl = useRef(null);

  useEffect(() => {
    ajax('Companies::Search', {
      workstationCounty: props.match.params.county
    })
      .then(response => {
        if (response.ok) {
          setCompanies(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterCompanies = company => {
    return false || (
        !!~`${company.name} ${company.rarCode} ${company.workstationCounty} ${company.workstationCity} ${company.workstationAddress}`.toLowerCase().indexOf(filter.toLowerCase())
    );
  };

  const getMapBounds = () => {
    var minLat = 1000,
        maxLat = 0,
        minLng = 1000,
        maxLng = 0;
    companies.filter(company => Boolean(company.latitude) && Boolean(company.longitude)).forEach(company => {
      minLat = Math.min(minLat, +company.latitude);
      maxLat = Math.max(maxLat, +company.latitude);
      minLng = Math.min(minLng, +company.longitude);
      maxLng = Math.max(maxLng, +company.longitude);
    });
    return {
      ne: {
        lat: maxLat,
        lng: maxLng
      },
      sw: {
        lat: minLat,
        lng: minLng
      },
    };
  };

  const getMapCenter = () => {
    var bounds = getMapBounds();
    return {
      lat: bounds.ne.lat === 0 ? 45.465109 : (bounds.sw.lat + bounds.ne.lat) / 2,
      lng: bounds.ne.lng === 0 ? 25.284422 : (bounds.sw.lng + bounds.ne.lng) / 2
    };
  };

  const getMapSize = () => {
    return {
      width: googleMapsEl.current ? googleMapsEl.current.clientWidth : 320,
      height: googleMapsEl.current ? googleMapsEl.current.clientHeight : 320
    };
  };

  const getMapZoom = () => {
    var bounds = getMapBounds();
    var mapDim = getMapSize();

    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.ne;
    var sw = bounds.sw;

    var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    var lngDiff = ne.lng - sw.lng;
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value}});
  };

  const handleSave = async () => {
    ajax('Companies::Save', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('Companies::Search', {
            workstationCounty: props.match.params.county
          })
            .then(response => {
              if (response.ok) {
                setCompanies(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Harta {props.match.params.county}</Typography>
      <Typography color='textSecondary'>Harta unitatilor autorizate ITP din judet</Typography>
      <Divider className={classes.divider} />

      {isLoaded && companies.length > 0 && view.mode === 'initial' &&
        <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      }

      {isLoaded && view.mode === 'initial' && companies.length === 0 &&
        <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
      }

      {isLoaded && view.mode === 'initial' && companies.length > 0 && companies.filter(filterCompanies).length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Filtrele aplicate de dumneavoastra nu au nici un rezultat.</Typography>
        </Fragment>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && view.mode === 'edit' &&
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.name.length < 6}
              helperText={view.value.name.length < 6 ? 'Numele societatii, min. 6 caractere' : ' '}
              name='name'
              label='Denumire societate'
              placeholder='Denumire societate'
              value={view.value.name}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ disabled inputProps={{maxLength: 5}}
              error={!Boolean(view.value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/))}
              helperText={!Boolean(view.value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/)) ? 'Numar autorizare RAR' : ' '}
              name='rarCode'
              label='Numar autorizare RAR'
              placeholder='Numar autorizare RAR'
              value={view.value.rarCode}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.headquarterCounty.length < 3}
              helperText={view.value.headquarterCounty.length < 3 ? 'Judet sediu social societate, min. 3 caractere' : ' '}
              name='headquarterCounty'
              label='Judet sediu social'
              placeholder='Judet sediu social'
              value={view.value.headquarterCounty}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.headquarterCity.length < 3}
              helperText={view.value.headquarterCity.length < 3 ? 'Localitate sediu social societate, min. 3 caractere' : ' '}
              name='headquarterCity'
              label='Localitate sediu social'
              placeholder='Localitate sediu social'
              value={view.value.headquarterCity}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 255}}
              error={view.value.headquarterAddress.length < 10}
              helperText={view.value.headquarterAddress.length < 10 ? 'Adresa sediu social societate, min. 10 caractere' : ' '}
              name='headquarterAddress'
              label='Adresa sediu social'
              placeholder='Adresa sediu social'
              value={view.value.headquarterAddress}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 16}}
              // error={view.value.registerNumber.length < 10}
              helperText={view.value.registerNumber.length < 10 ? 'Registrul comertului, min. 10 caractere' : ' '}
              name='registerNumber'
              label='Registrul comertului'
              placeholder='Registrul comertului'
              value={view.value.registerNumber}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 16}}
              // error={view.value.taxCode.length < 2}
              helperText={view.value.taxCode.length < 2 ? 'CUI fiscala, min. 2 caractere' : ' '}
              name='taxCode'
              label='Cod unic de inregistrare fiscala'
              placeholder='Cod unic de inregistrare fiscala'
              value={view.value.taxCode}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.workstationCounty.length < 3}
              helperText={view.value.workstationCounty.length < 3 ? 'Judet statie ITP, min. 3 caractere' : ' '}
              name='workstationCounty'
              label='Judet statie ITP'
              placeholder='Judet statie ITP'
              value={view.value.workstationCounty}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.workstationCity.length < 3}
              helperText={view.value.workstationCity.length < 3 ? 'Localitate statie ITP, min. 3 caractere' : ' '}
              name='workstationCity'
              label='Localitate statie ITP'
              placeholder='Localitate statie ITP'
              value={view.value.workstationCity}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 255}}
              error={view.value.workstationAddress.length < 10}
              helperText={view.value.workstationAddress.length < 10 ? 'Adresa statie ITP, min. 10 caractere' : ' '}
              name='workstationAddress'
              label='Adresa statie ITP'
              placeholder='Adresa statie ITP'
              value={view.value.workstationAddress}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 128}}
              // error={!Boolean(view.value.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i))} //eslint-disable-line
              helperText={!Boolean(view.value.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) ? 'O adresa de email valida' : ' '} //eslint-disable-line
              name='email'
              label='Adresa de email'
              placeholder='Adresa de email'
              value={view.value.email}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 10}}
              // error={!Boolean(view.value.phone.match(/^07[0-9]{8}$/))}
              helperText={!Boolean(view.value.phone.match(/^07[0-9]{8}$/)) ? 'Numar de telefon, fix 10 caractere' : ' '}
              name='phone'
              label='Numar de telefon'
              placeholder='Numar de telefon'
              value={view.value.phone}
              onChange={handleValueChange}
            />
          </Grid>
          {/* <Grid item xs={6} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' disabled
              // error={!Boolean(view.value.latitude.match(/^[0-9]{2}\.[0-9]{6}$/))}
              helperText={!Boolean(view.value.latitude.match(/^[0-9]{2}\.[0-9]{6}$/)) ? 'Latitudine (ex: 12.345678)' : ' '}
              name='latitude'
              label='Latitudine'
              placeholder='Latitudine'
              value={view.value.latitude}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' disabled
              // error={!Boolean(view.value.longitude.match(/^[0-9]{2}\.[0-9]{6}$/))}
              helperText={!Boolean(view.value.longitude.match(/^[0-9]{2}\.[0-9]{6}$/)) ? 'Longitudine (ex: 12.345678)' : ' '}
              name='longitude'
              label='Longitudine'
              placeholder='Longitudine'
              value={view.value.longitude}
              onChange={handleValueChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ disabled
              name='idSmsSender'
              label='Numar SMS sender'
              placeholder='Numar SMS sender'
              value={view.value.idSmsSender}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAA9ee_0ey2zxBJdjPx4klbu_ist7ej_yw' }}
              defaultCenter={{
                lat: +view.value.lat,
                lng: +view.value.lng
              }}
              defaultZoom={+view.value.zoom}
              style={{
                width: '100%',
                height: 0,
                margin: 0,
                  padding: '100% 0px 0px 0px',
                  position: 'relative'
                }}
              options={(maps) => {
                return {
                  mapTypeId: maps.MapTypeId.HYBRID,
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: maps.ControlPosition.TOP_LEFT
                  }
                }
              }}
              onClick={e => {
                setView({...view, value: {...view.value, latitude: e.lat.toFixed(6), longitude: e.lng.toFixed(6)}});
              }}
            >
              <AnyReactComponent
                lat={view.value.latitude}
                lng={view.value.longitude}
                text={view.value.rarCode}
                onClick={() => setView({...view, value: {...view.value, latitude: '', longitude: ''}})}
              />
            </GoogleMapReact>
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {companies.filter(filterCompanies).filter(company => !Boolean(company.latitude) && !Boolean(company.longitude)).map(company =>
            <ListItem key={company.id} dense button divider
              onClick={() => setView({mode: 'edit', value: company})}
            >
              <ListItemIcon>
                {company.isBillable === null &&
                  <SupervisorAccountIcon color='primary' />
                }
                {company.isBillable === '1' && true &&
                  <VerifiedUserIcon color='primary' />
                }
                {company.isBillable === '1' && false &&
                  <MoneyOffIcon color='secondary' />
                }
              </ListItemIcon>
              <ListItemText
                primary={`${company.name} (${company.rarCode})`}
                secondary={
                  <Typography variant='body2' color='textSecondary'>
                    {Boolean(company.latitude) && Boolean(company.longitude) &&
                      <LocationOnIcon fontSize='small' color='primary' style={{verticalAlign: 'bottom'}} />
                    }
                    {`${company.workstationAddress}, ${company.workstationCity}, jud. ${company.workstationCounty}`}
                  </Typography>
                }
              />
              {user.id === '1' &&
                <ListItemSecondaryAction>
                  <IconButton edge='end'
                    onClick={() => props.deleteEntity({
                      title: 'Stergere statie ITP',
                      question: 'Sunteti sigur ca doriti stergerea acestei statii ITP?',
                      content: 'Dupa aceasta operatiune, conturile asociate acestei statii ITP nu vor mai avea acces la aplicatii, iar SMS-urile acesteia nu se vor mai trimite.',
                      entity: `${company.name} (${company.workstationAddress}, ${company.workstationCity}, ${company.workstationAddress})`,
                      op: 'Companies::Delete',
                      id: company.id,
                      history: props.history,
                      pathname: props.location.pathname
                    })}
                  >
                    <DeleteIcon color='secondary' />
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItem>
          )}
          <ListItem dense divider ref={googleMapsEl}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAA9ee_0ey2zxBJdjPx4klbu_ist7ej_yw' }}
              defaultCenter={getMapCenter()}
              defaultZoom={getMapZoom()}
              style={{
                width: '100%',
                height: 0,
                margin: 0,
                  padding: '100% 0px 0px 0px',
                  position: 'relative'
                }}
              options={(maps) => {
                return {
                  mapTypeId: maps.MapTypeId.HYBRID,
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: maps.ControlPosition.TOP_LEFT
                  }
                }
              }}
            >
              {companies.filter(filterCompanies).filter(company => Boolean(company.latitude) && Boolean(company.longitude)).map(company =>
                <AnyReactComponent key={company.id}
                  lat={company.latitude}
                  lng={company.longitude}
                  text={company.rarCode}
                />
              )}
            </GoogleMapReact>
          </ListItem>
        </List>
      }

      {isLoaded && view.mode === 'edit' &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && view.mode === 'edit' &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            view.value.name.length < 6 ||
            view.value.headquarterCounty.length < 3 ||
            view.value.headquarterCity.length < 3 ||
            view.value.headquarterAddress.length < 10 ||
            // view.value.registerNumber.length < 10 ||
            // view.value.taxCode.length < 2 ||
            !Boolean(view.value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/)) ||
            view.value.workstationCounty.length < 3 ||
            view.value.workstationCity.length < 3 ||
            view.value.workstationAddress.length < 10 ||
            // !Boolean(view.value.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) || //eslint-disable-line
            // !Boolean(view.value.phone.match(/^07[0-9]{8}$/))
            // !Boolean(view.value.latitude.match(/^[0-9]{2}\.[0-9]{6}$/))
            // !Boolean(view.value.longitude.match(/^[0-9]{2}\.[0-9]{6}$/))
            false
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}