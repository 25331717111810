import React, {Fragment, useState} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  }
}));

export default function Signup(props) {
  const classes = useStyles();
  const [value, setValue] = useState({
    rarCode: '',
    phone: '',
    referralCode: '',
    reCaptcha: false,
    signupToken: '',
    surname: '',
    name: '',
    password: '',
  });
  const [view, setView] = useState({
    view: 'phoneStage', // phoneStage, tokenStage, passwordStage
    value: null
  });

  const handleValueChange = e => {
    setValue({...value, [e.target.name]: e.target.value});
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter')
      submitValue();
  };

  const submitValue = async () => {
    if (view.view === 'phoneStage') {
      props.ajax('Users::Signup', value)
        .then(response => {
          if (response.ok) {
            if (response.data === true)
              setView({
                view: 'tokenStage',
                value: null
              });
          }
        });
    }
    if (view.view === 'tokenStage') {
      props.ajax('Users::CheckSignupToken', value)
        .then(response => {
          if (response.ok) {
            if (response.data === true)
              setView({
                view: 'passwordStage',
                value: null
              });
          }
        });
    }
    if (view.view === 'passwordStage') {
      props.ajax('Users::SignupUsingToken', value)
        .then(response => {
          if (response.ok) {
            localStorage.setObject('user', response.data);
            props.setUser(response.data);
          }
        });
    }
  };

  return (
    <Container fixed maxWidth='xs'>
      <CardMedia image='/appLogo_500.png' style={{height: '0px', paddingTop: `${170 / 500 * 100}%`}} />
      <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 5}} disabled={props.isLoading || !!~['tokenStage', 'passwordStage'].indexOf(view.view)}
        error={!Boolean(value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/i))}
        helperText={!Boolean(value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/i)) ? 'Numar autorizare RAR' : ' '}
        name='rarCode'
        label='Numar autorizatie RAR'
        value={value.rarCode}
        onKeyPress={handleKeyPress}
        onChange={handleValueChange}
      />
      <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 10}} disabled={props.isLoading || !!~['tokenStage', 'passwordStage'].indexOf(view.view)}
        error={!Boolean(value.phone.match(/^07[0-9]{8}$/))}
        helperText={!Boolean(value.phone.match(/^07[0-9]{8}$/)) ? 'Numar de telefon, 07xxx' : ' '}
        name='phone'
        label='Numar de telefon'
        value={value.phone}
        onKeyPress={handleKeyPress}
        onChange={handleValueChange}
      />
      <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 6}} disabled={props.isLoading}
        helperText='Daca ati primit un cod promo, completati aici'
        name='referralCode'
        label='Cod promo'
        value={value.referralCode}
        onKeyPress={handleKeyPress}
        onChange={handleValueChange}
      />
      {!!~['tokenStage', 'passwordStage'].indexOf(view.view) &&
        <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 6}} disabled={props.isLoading}
          error={value.signupToken.length < 6}
          helperText={value.signupToken.length < 6 ? 'Codul primit pe SMS, 6 caractere' : ' '}
          name='signupToken'
          label='Codul primit pe SMS'
          value={value.signupToken}
          onKeyPress={handleKeyPress}
          onChange={handleValueChange}
        />
      }
      {view.view === 'passwordStage' &&
        <Fragment>
          <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 64}} disabled={props.isLoading}
            error={value.surname.length < 3}
            helperText={value.surname.length < 3 ? 'Nume, min. 3 caractere' : ' '}
            name='surname'
            label='Nume'
            value={value.surname}
            onKeyPress={handleKeyPress}
            onChange={handleValueChange}
          />
          <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 64}} disabled={props.isLoading}
            error={value.name.length < 3}
            helperText={value.name.length < 3 ? 'Prenume, min. 3 caractere' : ' '}
            name='name'
            label='Prenume'
            value={value.name}
            onKeyPress={handleKeyPress}
            onChange={handleValueChange}
          />
          <TextField fullWidth size='small' variant='outlined' margin='dense' required disabled={props.isLoading} type='password'
            error={value.password.length < 6}
            helperText={value.password.length < 6 ? 'Parola, min. 6 caractere' : ' '}
            name='password'
            label='Parola'
            value={value.password}
            onKeyPress={handleKeyPress}
            onChange={handleValueChange}
          />
        </Fragment>
      }
      {view.view === 'phoneStage' &&
        <Box>
          <ReCAPTCHA style={{width: '304px', margin: '0px auto 16px'}}
            sitekey={props.reCAPTCHA_siteKey}
            onChange={captcha => setValue({...value, reCaptcha: captcha})}
            onExpired={() => setValue({...value, reCaptcha: false})}
            onErrored={() => setValue({...value, reCaptcha: false})}
          />
          <Box mb={1}>
            <Button size='small'
              onClick={() => setView({view: 'tokenStage', value: null})}
            >
              Am deja codul primit pe SMS
            </Button>
          </Box>
          <Button fullWidth size='small' variant='contained' color='primary'
            disabled={props.isLoading || !Boolean(value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/i)) || !Boolean(value.phone.match(/^07[0-9]{8}$/)) || value.reCaptcha === false}
            onClick={submitValue}
          >
            Verifica datele
          </Button>
        </Box>
      }
      {view.view === 'tokenStage' &&
        <Button fullWidth size='small' variant='contained' color='primary'
          disabled={props.isLoading || !Boolean(value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/i)) || !Boolean(value.phone.match(/^07[0-9]{8}$/)) || value.signupToken.length < 6}
          onClick={submitValue}
        >
          Verifica codul
        </Button>
      }
      {view.view === 'passwordStage' &&
        <Button fullWidth size='small' variant='contained' color='primary'
          disabled={props.isLoading || !Boolean(value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/i)) || !Boolean(value.phone.match(/^07[0-9]{8}$/)) || value.signupToken.length < 6 || value.surname.length < 3 || value.name.length < 3 || value.password.length < 6}
          onClick={submitValue}
        >
          Creaza contul
        </Button>
      }
      <Box mt={3} style={{display: 'flex', justifyContent: 'space-between'}}>
        <Link to='/ResetPassword' className={classes.link}>
          <Button size='small' color='secondary' disabled={props.isLoading}>
            Am uitat parola
          </Button>
        </Link>
        <Link to='/Login' className={classes.link}>
          <Button size='small' color='primary' disabled={props.isLoading}>
            Inapoi la autentificare
          </Button>
        </Link>
      </Box>
    </Container>
  );
}