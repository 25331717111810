import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function Billing(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const [isLoaded, setIsLoaded] = useState(false);
  const [billing, setBilling] = useState({
    subscriptionType: '',
    usage: '',
    invoices: []
  });

  useEffect(() => {
    ajax('Billing::Details')
      .then(response => {
        if (response.ok) {
          setBilling(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Facturare</Typography>
      <Typography color='textSecondary'>Detalii financiare asociate contului</Typography>
      <Divider className={classes.divider} />

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded &&
        <Fragment>
          <List>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Pentru detalii financiare'
                secondary=''
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary', style: {whiteSpace: 'pre-wrap'}}}
                primary='Tip abonament'
                secondary={
                  billing.subscriptionType.isBillable === null
                    ? 'Admin nelimitat'
                    : billing.subscriptionType.isBillable === '0'
                      ? `Varianta demo, mai aveti ${billing.subscriptionType.smsLimit} SMS-uri gratuite.\nPentru trecerea la varianta platita, va rugam sa ne contactati la numarul de telefon 0770040011.`
                      : `Pret abonament: ${billing.subscriptionType.subscriptionPrice} €\nPret per SMS: ${billing.subscriptionType.smsPrice} €`
                }
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='SMS-uri de facturat in luna curenta'
                secondary={billing.usage}
              />
            </ListItem>
            <ListItem dense divider>
              <Typography variant='h6'>Facturi</Typography>
            </ListItem>
            {billing.invoices.length === 0 &&
              <ListItem dense divider>
                <Typography variant='subtitle2'>Nu aveti facturi emise pana in acest moment</Typography>
              </ListItem>
            }
            {billing.invoices.map(invoice =>
              <ListItem key={invoice.id} dense divider>
                <ListItemText
                  primaryTypographyProps={{color: 'textSecondary'}}
                  secondaryTypographyProps={{color: invoice.paidAt ? 'textPrimary' : 'secondary'}}
                  primary={`Factura fiscala ${invoice.serial} ${invoice.number}/${invoice.createdAt}`}
                  secondary={`${invoice.value} RON - ${invoice.paidAt ? `platita pe ${invoice.paidAt}` : `scadenta la data de ${invoice.dueDate}`}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge='end'
                    onClick={console.log(invoice.id)}
                  >
                    <CloudDownloadIcon color='primary' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Fragment>
      }
    </Container>
  );
}