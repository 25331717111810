import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function MyAccount(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, edit
    value: null
  });
  const [value, setValue] = useState({
    users: [],
    user: {},
    company: {}
  });

  useEffect(() => {
    ajax('Users::MyAccount')
      .then(response => {
        if (response.ok) {
          setValue(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUserValueChange = e => {
    setView({...view, value: {...view.value, user: {...view.value.user, [e.target.name]: e.target.value}}});
  };
  const handleCompanyValueChange = e => {
    setView({...view, value: {...view.value, company: {...view.value.company, [e.target.name]: e.target.value}}});
  };

  const handleSave = async () => {
    ajax('Users::SaveMyAccount', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(true);
          setValue(view.value);
          setView({
            mode: 'initial',
            value: null
          });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Contul meu</Typography>
      <Typography color='textSecondary'>Detaliile sunt necesare pentru accesul in aplicatie si pentru facturare</Typography>
      <Divider className={classes.divider} />

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && view.mode === 'edit' &&
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h6'>Detalii personale</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' disabled
                name='username'
                label='Nume de utilizator'
                placeholder='Nume de utilizator'
                value={view.value.user.username}
                onChange={handleUserValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.user.surname.length < 3}
                helperText={view.value.user.surname.length < 3 ? 'Numele persoanei de contact, min. 3 caractere' : ' '}
                name='surname'
                label='Nume'
                placeholder='Nume'
                value={view.value.user.surname}
                onChange={handleUserValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.user.name.length < 3}
                helperText={view.value.user.name.length < 3 ? 'Prenumele persoanei de contact, min. 3 caractere' : ' '}
                name='name'
                label='Prenume'
                placeholder='Prenume'
                value={view.value.user.name}
                onChange={handleUserValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 13}}
                error={!Boolean(view.value.user.cnp.match(/^\d{13}$/))}
                helperText={!Boolean(view.value.user.cnp.match(/^\d{13}$/)) ? 'CNP-ul persoanei de contact, fix 13 cifre' : ' '}
                name='cnp'
                label='CNP'
                placeholder='CNP'
                value={view.value.user.cnp}
                onChange={handleUserValueChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>Detalii companie</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.company.name.length < 6}
                helperText={view.value.company.name.length < 6 ? 'Numele societatii, min. 6 caractere' : ' '}
                name='name'
                label='Denumire societate'
                placeholder='Denumire societate'
                value={view.value.company.name}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ disabled inputProps={{maxLength: 5}}
                error={!Boolean(view.value.company.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/))}
                helperText={!Boolean(view.value.company.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/)) ? 'Numar autorizare RAR' : ' '}
                name='rarCode'
                label='Numar autorizatie RAR'
                placeholder='Numar autorizatie RAR'
                value={view.value.company.rarCode}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.company.headquarterCounty.length < 3}
                helperText={view.value.company.headquarterCounty.length < 3 ? 'Judet sediu social societate, min. 3 caractere' : ' '}
                name='headquarterCounty'
                label='Judet sediu social'
                placeholder='Judet sediu social'
                value={view.value.company.headquarterCounty}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.company.headquarterCity.length < 3}
                helperText={view.value.company.headquarterCity.length < 3 ? 'Localitate sediu social societate, min. 3 caractere' : ' '}
                name='headquarterCity'
                label='Localitate sediu social'
                placeholder='Localitate sediu social'
                value={view.value.company.headquarterCity}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 255}}
                error={view.value.company.headquarterAddress.length < 10}
                helperText={view.value.company.headquarterAddress.length < 10 ? 'Adresa sediu social societate, min. 10 caractere' : ' '}
                name='headquarterAddress'
                label='Adresa sediu social'
                placeholder='Adresa sediu social'
                value={view.value.company.headquarterAddress}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 16}}
                error={view.value.company.registerNumber.length < 10}
                helperText={view.value.company.registerNumber.length < 10 ? 'Registrul comertului, min. 10 caractere' : ' '}
                name='registerNumber'
                label='Registrul comertului'
                placeholder='Registrul comertului'
                value={view.value.company.registerNumber}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 16}}
                error={view.value.company.taxCode.length < 2}
                helperText={view.value.company.taxCode.length < 2 ? 'CUI fiscala, min. 2 caractere' : ' '}
                name='taxCode'
                label='Cod unic de inregistrare fiscala'
                placeholder='Cod unic de inregistrare fiscala'
                value={view.value.company.taxCode}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.company.workstationCounty.length < 3}
                helperText={view.value.company.workstationCounty.length < 3 ? 'Judet statie ITP, min. 3 caractere' : ' '}
                name='workstationCounty'
                label='Judet statie ITP'
                placeholder='Judet statie ITP'
                value={view.value.company.workstationCounty}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
                error={view.value.company.workstationCity.length < 3}
                helperText={view.value.company.workstationCity.length < 3 ? 'Localitate statie ITP, min. 3 caractere' : ' '}
                name='workstationCity'
                label='Localitate statie ITP'
                placeholder='Localitate statie ITP'
                value={view.value.company.workstationCity}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 255}}
                error={view.value.company.workstationAddress.length < 10}
                helperText={view.value.company.workstationAddress.length < 10 ? 'Adresa statie ITP, min. 10 caractere' : ' '}
                name='workstationAddress'
                label='Adresa statie ITP'
                placeholder='Adresa statie ITP'
                value={view.value.company.workstationAddress}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 128}}
                error={!Boolean(view.value.company.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i))} //eslint-disable-line
                helperText={!Boolean(view.value.company.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) ? 'O adresa de email valida' : ' '} //eslint-disable-line
                name='email'
                label='Adresa de email'
                placeholder='Adresa de email'
                value={view.value.company.email}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 10}}
                error={!Boolean(view.value.company.phone.match(/^07[0-9]{8}$/))}
                helperText={!Boolean(view.value.company.phone.match(/^07[0-9]{8}$/)) ? 'Numar de telefon, 07xxx' : ' '}
                name='phone'
                label='Numar de telefon'
                placeholder='Numar de telefon'
                value={view.value.company.phone}
                onChange={handleCompanyValueChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ disabled
                name='smsSender'
                label='Numar SMS sender'
                placeholder='Numar SMS sender'
                value={view.value.company.smsSender}
                onChange={handleCompanyValueChange}
              />
            </Grid>
          </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <Fragment>
          <List>
            {localStorage.getObject('user').createdBy === '0' &&
              <Fragment>
                <ListItem dense divider>
                  <Typography variant='h6'>Utilizatori asociati contului</Typography>
                </ListItem>
                <ListItem dense divider button>
                  <Link to='/MyUsers' className={classes.link}>
                    <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'primary'}}
                      primary='Utilizatori'
                      secondary='Apasati aici pentru administrarea utilizatorilor'
                    />
                  </Link>
                </ListItem>
              </Fragment>
            }
            {Boolean(localStorage.getObject('user').referralCode) &&
              <Fragment>
                <ListItem dense divider>
                  <Typography variant='h6'>Cod promo individual</Typography>
                </ListItem>
                <ListItem dense divider>
                  <ListItemText disableTypography
                    primary={
                      <Fragment>
                        <Typography variant='body2'>Recomandati si castigati!</Typography>
                        <Typography variant='body2' color='textSecondary' align='justify'>Pentru orice statie ITP care introduce codul de mai jos la inregistrarea in aplicatie, vor exista beneficii de ambele parti.</Typography>
                        <Typography variant='body2' color='textSecondary' align='justify'>* Agentul primeste un comision (doar in situatia in care clientul isi schimba tipul contului din 'Demo' in 'Activ').</Typography>
                        <Typography variant='body2' color='textSecondary' align='justify'>* Clientul primeste o reducere procentuala (10%) pentru primele trei facturi emise.</Typography>
                      </Fragment>
                    }
                    secondary={
                      <Typography variant='body2' color='primary'>Codul dumneavoastra este: {value.user.referralCode}</Typography>
                    }
                  />
                </ListItem>
              </Fragment>
            }
            <ListItem dense divider>
              <Typography variant='h6'>Detalii personale</Typography>
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Nume de utilizator'
                secondary={value.user.username}
              />
            </ListItem>
            <ListItem dense divider button>
              <Link to='/ChangePassword' className={classes.link}>
                <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'primary'}}
                  primary='Parola'
                  secondary='Apasati aici pentru schimbarea parolei'
                />
              </Link>
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Nume'
                secondary={value.user.surname}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Prenume'
                secondary={value.user.name}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='CNP'
                secondary={value.user.cnp}
              />
            </ListItem>
            <ListItem dense divider>
              <Typography variant='h6'>Detalii companie</Typography>
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: Boolean(parseInt(value.company.isBillable)) ? 'primary' : 'secondary'}}
                primary='Tip cont'
                secondary={
                  value.company.isBillable === null
                    ? (
                      Boolean(parseInt(localStorage.getObject('user').createdBy))
                        ? 'Agent'
                        : 'Admin'
                      )
                    : (
                      Boolean(parseInt(value.company.isBillable))
                        ? 'Activ'
                        : 'Demo'
                      )
                }
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Denumire societate'
                secondary={value.company.name}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Numar autorizare RAR'
                secondary={value.company.rarCode}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Adresa sediu social'
                secondary={`${value.company.headquarterAddress}, ${value.company.headquarterCity}, judetul ${value.company.headquarterCounty}`}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Registrul comertului'
                secondary={value.company.registerNumber}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Cod unic de inregistrare fiscala'
                secondary={value.company.taxCode}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Adresa punct de lucru'
                secondary={`${value.company.workstationAddress}, ${value.company.workstationCity}, judetul ${value.company.workstationCounty}`}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Adresa de email'
                secondary={value.company.email}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Numar de telefon'
                secondary={value.company.phone}
              />
            </ListItem>
            <ListItem dense divider>
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary'}}
                primary='Sender SMS'
                secondary={value.company.smsSender}
              />
            </ListItem>
          </List>
        </Fragment>
      }

      {isLoaded && view.mode === 'initial' && localStorage.getObject('user').createdBy === '0' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'edit', value: value})}
        >
          <EditIcon />
        </Fab>
      }

      {isLoaded && view.mode === 'edit' &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && view.mode === 'edit' &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            view.value.user.surname.length < 3 ||
            view.value.user.name.length < 3 ||
            !Boolean(view.value.user.cnp.match(/^\d{13}$/)) ||
            view.value.company.name.length < 6 ||
            view.value.company.headquarterCounty.length < 3 ||
            view.value.company.headquarterCity.length < 3 ||
            view.value.company.headquarterAddress.length < 10 ||
            view.value.company.registerNumber.length < 10 ||
            view.value.company.taxCode.length < 2 ||
            !Boolean(view.value.company.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/)) ||
            view.value.company.workstationCounty.length < 3 ||
            view.value.company.workstationCity.length < 3 ||
            view.value.company.workstationAddress.length < 10 ||
            !Boolean(view.value.company.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) || //eslint-disable-line
            !Boolean(view.value.company.phone.match(/^07[0-9]{8}$/))
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}