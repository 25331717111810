import React from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  subtitle1: {
    marginTop: theme.spacing(3),
    textIndent: theme.spacing(3)
  },
  subtitle2: {
    marginTop: theme.spacing(2),
    textIndent: theme.spacing(3)
  },
  body2: {
    marginTop: theme.spacing(1),
    textIndent: theme.spacing(3)
  }
}));

export default function TandC(props) {
  const classes = useStyles();
  const value = {
    username: localStorage.getObject('user').username,
    agreement: '1'
  };

  const submitValue = async () => {
    props.ajax('Users::SetAgreement', value)
      .then(response => {
        if (response.ok) {
          localStorage.setObject('user', response.data);
          props.setUser(response.data);
        }
      });
  };

  return (
    <Container fixed maxWidth='sm' style={{marginTop: '64px', marginBottom: '24px'}}>
      <CardMedia image='/appLogo_500.png' style={{height: '0px', paddingTop: `${170 / 500 * 100}%`}} />

      <Typography variant='subtitle1' className={classes.subtitle1}>Condiţii Generale pentru utilizarea site-ului www.itpauto.eu</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>INTRODUCERE</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Prezentele Condiţii Generale definesc condiţiile de utilizare a site-ului www.itpauto.eu de către potenţialii vizitatori sau clienţi. Accesând şi navigând pe acest site, acceptaţi termenii de utilizare descrişi în continuare.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>DREPTURILE ASUPRA CONŢINUTULUI SITE-ULUI</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>S.C. Integrated Software S.R.L. (denumită în continuare Integrated Software), nu va transfera titlul de proprietate asupra aplicaţiilor software (soluţiile ITPauto).</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Integrated Software deţine drepturi complete şi depline asupra titlului de proprietate şi prin aceasta toate drepturile de autor şi cele brevetate. Nu aveţi dreptul să redistribuiţi, vindeţi, decompilaţi, dezasamblaţi aplicaţia software într-o formă perceptibilă de către oameni.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Toate informaţiile, produsele sau aplicaţiile conţinute în acest site sunt proprietatea Integrated Software, care îşi rezervă dreptul de a modifica conţinutul şi/sau structura site-ului în orice moment şi fară nici o informare prealabilă.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Întregul conţinut al site-ului www.itpauto.eu este protejat prin Legea drepturilor de autor, toate drepturile fiind rezervate. Toate drepturile referitoare la pagini, conţinutul şi prezentarea site-ului sunt deţinute de Integrated Software. Este interzisă copierea, modificarea, afişarea, distribuirea, transmiterea, publicarea, comercializarea, licenţierea, crearea unor materiale derivate sau utilizarea conţinutului site-ului în orice scop fară confirmarea scrisă din partea Integrated Software.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Accesul si utilizarea paginii http://www.itpauto.eu/ sunt gratuite si au scopul de a veni in ajutorul utilizatorilor pentru a găsi informațiile necesare în cel mai ușor si rapid mod posibil, conform cerințelor fiecăruia.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Informațiile prezente pe ITPauto.eu sunt de interes general și sunt puse la dispoziția utilizatorilor în mod gratuit. Prin termenul „utilizator” al acestei pagini se înțelege orice persoana fizică sau juridică care va accesa pagina. Puteți copia și tipări conținutul paginii ITPauto.eu pentru folosința dumneavoastră personală, fără intenții comerciale. În orice alte situații, conținutul ITPauto.eu nu poate fi reprodus, modificat sau exploatat fără consimțământul explicit al reprezentanților ITPauto.eu.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>NEANGAJAREA RĂSPUNDERII</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Conţinutul informaţiilor se referă la descrierea într-un anumit grad de detaliere a activităţilor, produselor şi serviciilor Integrated Software. Integrated Software nu va acorda nici o garanţie referitoare la:</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>– evitarea utilizării anevoioase sau întreruperii în utilizare a site-ului;</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>– neafectarea în sens negativ a altor sisteme prin utilizarea site-ului;</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>– inexistenţa pe site-ul pus la dispoziţie a viruşilor sau alte componente care ar putea dăuna utilizatorilor.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Astfel, Integrated Software nu poate fi responsabilă pentru nici un fel de daune directe sau indirecte produse prin utilizarea site-ului său.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Toate informațiile prezentate pe site cu privire la produsele ITPauto, prețuri, informații, campanii și promoții de marketing, aspecte tehnice, sunt prezentate cu titlu informativ. Nimic din conținutul site-ului www.itpauto.eu nu poate constitui o ofertă fermă de a contracta și nu poate angaja răspunderea Integrated Software în lipsa unor acorduri ulterioare.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>OBIECTIVUL CONŢINUTULUI SITE-ULUI</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Obiectivul conţinutului site-ului este de a transmite informaţii actualizate şi exacte.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Integrated Software nu poate garanta că prezentele pagini nu conţin erori, şi asigură că va depune toate diligenţele pentru realizarea unei informări corecte şi remedierea eventualelor erori.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Orice persoană care doreşte să-şi procure unul din serviciile sau produsele prezentate în site este rugată să contacteze Integrated Software prin unul din mijloacele afişate în pagina de Contact a site-ului, pentru a se informa atât asupra disponibilităţii serviciului sau produsului în cauză cât şi asupra condiţiilor contractuale, tarifelor şi informaţiilor tehnice sau de altă natură.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>INFORMAŢII PERSONALE</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Când prin intermediul prezentului site vă sunt solicitate informaţii, aceasta are ca scop identificarea dumneavoastră sau posibilitatea de a vă contacta. Acest lucru este cu atât mai necesar în cazul folosirii aplicaţiilor disponibile pe site.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Natura informaţiilor solicitate se referă în special la date personale (nume, adresă, numere de telefon), adresă de e-mail, modul în care sunt utilizate sau intenţionează să fie utilizate produsele şi/sau serviciile Integrated Software, dar poate include şi alte informaţii aflate în strânsă legătură cu utilizarea serviciilor şi/sau produselor solicitate.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Cu scopul de a răspunde mai bine nevoilor şi întrebărilor utilizatorilor site-ului, informaţiile solicitate prin intermediul acestui site vor face obiectul unei stocări şi prelucrări electronice.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>COMUNICAREA CU VIZITATORII SITE-ULUI</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Metodele prin care vizitatorii site-ului inițiază contactul cu noi sunt: adresă de e-mail, număr de telefon, formularele prezente pe site.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Această contactare are loc pentru a cere informații despre produsele noastre, pentru a ne solicita oferte de preț, pentru a ne consulta părerea pe diferite subiecte, pentru a beneficia de serviciile noastre, pentru a facilita procesul de plată.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Inițierea contactului de către d-voastră îl considerăm / reprezintă consimțământul d-voastră pentru a vi se răspunde înapoi, a vi se furniza informațiile, a primi detalii de plata sau orice informație solicitată.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Ulterior desfășurării comunicării inițiale, Integrated Software S.R.L. își stabilește ca interes legitim posibilitatea de a vă transmite și comunica informații ulterioare, relevante solicitării d-voastră, fără a avea caracter de newsletter. Aceste informații pot apărea în urma îmbunătățirii produselor noastre, lansarea de produse noi care vin în ajutorul d-voastră sau aspecte care au legătură cu tematica discutată.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>În acest sens Integrated Software SRL și-a stabilit un termen nelimitat de prelucrare ulterioară a datelor dumneavoastra.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>LEGĂTURA CU ALTE SITE-URI</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Prezentul site poate conţine legături sau trimiteri către alte site-uri considerate de Integrated Software utile în legătură cu conţinutul site-ului său şi care nu se află sub controlul sau îndrumarea sa. În cazul utilizării acestor legături sau trimiteri se vor aplica condiţiile generale de utilizare corespunzătoare acelor site-uri.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Integrated Software nu poate garanta/controla actualitatea/exactitatea informaţiilor prezente pe site-urile unor terţi, la care se face trimitere de pe site-ul său.</Typography>
      <Typography align='justify' variant='subtitle2' className={classes.subtitle2}>INFORMAŢIILE OFERITE PRIN INTERMEDIUL SITE-ULUI</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Orice persoană care vizitează site-ul www.itpauto.eu şi care oferă date sau informaţii cu caracter personal prin intermediul acestui site îşi manifestă acordul în mod expres şi neechivoc pentru următoarele: prelucrarea acestor date şi informaţii personale de către Integrated Software în vederea efectuării de studii de piaţă; transmiterii de materiale promoţionale specifice operaţiunilor de marketing direct; soluţionarea de către Integrated Software a cererilor, întrebărilor şi reclamaţiilor adresate (a se vedea pagina Contact din site); alte activităţi întreprinse de Integrated Software şi permise de lege, ce nu fac obiectul unei aprobări din partea destinatarului.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Integrated Software va păstra confidenţialitatea acestor informaţii.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Utilizarea în continuare a acestui site constituie acordul expres şi neechivoc al dumneavoastră în conformitate cu directivele GDPR pentru protecţia persoanelor cu privire la prelucrarea datelor cu caracter personal şi libera circulaţie a acestor date.</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Pentru orice nelămurire în legătură cu exercitarea drepturilor dumneavoastră referitoare la utilizarea site-ului şi la protecţia utilizării vă rugăm să ne contactaţi prin intermediul secţiunii Contact din site.</Typography>

      <Typography variant='subtitle1'>Politica cookie-urilor</Typography>
      <Typography align='justify' variant='body2' className={classes.body2}>Momentan, acest site nu utilizează cookie-uri.</Typography>

      <Button size='small' variant='contained' color='primary' disabled={props.isLoading} style={{marginTop: '24px'}}
        onClick={submitValue}
      >
        Am citit si sunt de acord
      </Button>
    </Container>
  );
}