import React, {useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function CompanyHistory(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const defaultValue = {
    id: '',
    idCompany: props.match.params.idCompany,
    description: '',
    createdBy: '',
    createdAt: ''
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, edit
    value: null
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    ajax('Companies::History', {
      id: props.match.params.idCompany
    })
      .then(response => {
        if (response.ok) {
          setData(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value}});
  };

  const handleSave = async () => {
    ajax('Companies::SaveHistory', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('Companies::History', {
            id: props.match.params.idCompany
          })
            .then(response => {
              if (response.ok) {
                setData(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Istoric statie ITP {data.company}</Typography>
      <Typography color='textSecondary'>Istoric statie ITP {data.company}</Typography>
      <Divider className={classes.divider} />

      {isLoaded && view.mode === 'initial' && data.length === 0 &&
        <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required multiline rows={6} rowsMax={10} autoFocus
              error={view.value.description.length < 10}
              helperText={view.value.description.length < 10 ? 'Descriere, min. 10 caractere' : ' '}
              name='description'
              label='Descriere'
              placeholder='Descriere'
              value={view.value.description}
              onChange={handleValueChange}
            />
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {data.map(item =>
            <ListItem key={item.id} dense button divider
              onClick={() => setView({mode: 'edit', value: item})}
            >
              <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'textPrimary', style: {whiteSpace: 'pre-wrap'}}}
                primary={`${item.createdAt} - ${item.createdBy}`}
                secondary={item.description}
              />
            </ListItem>
          )}
        </List>
      }

      {isLoaded && view.mode === 'initial' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'add', value: defaultValue})}
        >
          <AddIcon />
        </Fab>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            view.value.description.length < 10 ||
            false
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}