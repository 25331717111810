import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function Expirings(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, renew
    value: null
  });
  const [data, setData] = useState({
    carInspections: [],
    messages: []
  });
  const [expiredState, setExpiredState] = useState('1');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setIsLoaded(false);
    ajax('CarInspections::Expirings', {
      expiredState: expiredState
    })
      .then(response => {
        if (response.ok) {
          setData(response.data);
          setIsLoaded(true);
        }
      });
  }, [expiredState]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value.toUpperCase()}});
  };

  const handleValidityChange = e => {
    var date = new Date().addMonths(e.target.value);
    date = `${date.getFullYear() + 1}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    setView({...view, value: {...view.value, validity: e.target.value, validTo: date}});
  };

  const handleSave = async () => {
    ajax('CarInspections::Save', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('CarInspections::Expirings', {
            expiredState: expiredState
          })
            .then(response => {
              if (response.ok) {
                setData(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  const filterCarInspections = carInspection => {
    return false || (
        !!~`${carInspection.plate} ${carInspection.phone}`.toLowerCase().indexOf(filter.toLowerCase())
    );
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Expirari inspectii tehnice periodice</Typography>
      <Typography color='textSecondary'>Administrare inspectii tehnice periodice care urmeaza sa expire</Typography>
      <Divider className={classes.divider} />

      {isLoaded && view.mode === 'initial' &&
        <FormControl component='fieldset' style={{display: 'block'}}>
          <FormLabel component='legend'>Ce afisam?</FormLabel>
          <RadioGroup
            value={expiredState}
            onChange={e => setExpiredState(e.target.value)}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel control={<Radio color='primary' />}
                  label='Care vor expira in curand'
                  value='1'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel control={<Radio color='primary' />}
                  label='Care sunt expirate de curand'
                  value='-1'
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      }

      {isLoaded && view.mode === 'initial' && data.carInspections.length > 0 &&
        <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      }

      {isLoaded && view.mode === 'initial' && (data.carInspections.length === 0 || (data.carInspections.length > 0 && data.carInspections.filter(filterCarInspections).length === 0)) &&
        <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && view.mode === 'renew' &&
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 7}}
              error={!Boolean(view.value.plate.match(/^[A-Z]{1,2}[0-9]{2,3}[A-Z]{3}$/))}
              name='plate'
              label='Numar de inmatriculare'
              placeholder='Numar de inmatriculare'
              value={view.value.plate}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 10}}
              error={!Boolean(view.value.phone.match(/^07[0-9]{8}$/))}
              name='phone'
              label='Numar de telefon'
              placeholder='Numar de telefon'
              value={view.value.phone}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required select
              name='validity'
              label='Valabilitate ITP'
              placeholder='Valabilitate ITP'
              value={view.value.validity}
              onChange={handleValidityChange}
            >
              <MenuItem value='6'>6 luni</MenuItem>
              <MenuItem value='12'>1 an</MenuItem>
              <MenuItem value='24'>2 ani</MenuItem>
              <MenuItem value='36'>3 ani</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' type='date' required
              name='validTo'
              label='ITP-ul expira la data'
              placeholder='ITP-ul expira la data'
              value={view.value.validTo}
              onChange={handleValueChange}
            />
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {data.carInspections.filter(filterCarInspections).map(carInspection =>
            <ListItem key={carInspection.id} dense divider button={!Boolean(carInspection.idNewInspection)}
              onClick={e => setView({
                mode: 'renew',
                value: {
                  idOldInspection: carInspection.id,
                  plate: carInspection.plate,
                  phone: carInspection.phone,
                  validity: carInspection.validity,
                  validTo: `${new Date().getFullYear() + 1}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`
                }
              })}
            >
            <ListItemIcon>
              {Boolean(carInspection.idNewInspection) &&
                <CheckIcon color='primary' />
              }
            </ListItemIcon>
              <ListItemText disableTypography
                primary={carInspection.plate}
                secondary={
                  <Fragment>
                    <Typography variant='body2' color='textSecondary'>{`${carInspection.phone}, expira pe ${carInspection.validTo}`}</Typography>
                    <Typography variant='body2' color='textSecondary'>{`Adaugat de ${carInspection.createdBy} la data de ${carInspection.createdAt}`}</Typography>
                    <List>
                      {data.messages.filter(message => message.idCarInspection === carInspection.id).map(message =>
                        <ListItem key={message.id} dense divider>
                          <ListItemText disableTypography
                            primary={
                              <Fragment>
                                <Typography variant='body2' color='textPrimary'>{`SMS trimis la: ${message.smsSentAt}`}</Typography>
                                {message.smsDeliveredAt &&
                                  <Typography variant='body2' color='textPrimary'>{`SMS receptionat la: ${message.smsDeliveredAt}`}</Typography>
                                }
                                <Typography variant='body2' color='textPrimary'>{`SMS status: ${message.smsStatus}`}</Typography>
                              </Fragment>
                            }
                            secondary={message.body}
                          />
                        </ListItem>
                      )}
                    </List>
                  </Fragment>
                }
              />
            </ListItem>
          )}
        </List>
      }

      {isLoaded && view.mode === 'initial' &&
        <Link to='/CarInspections/add' className={classes.link}>
          <Fab color='primary' className={classes.fabBottomRight}>
            <AddIcon />
          </Fab>
        </Link>
      }

      {isLoaded && view.mode === 'renew' &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && view.mode === 'renew' &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            !Boolean(view.value.plate.match(/^[A-Z]{1,2}[0-9]{2,3}[A-Z]{3}$/)) ||
            !Boolean(view.value.phone.match(/^07[0-9]{8}$/))
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}