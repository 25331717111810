import React, {useState} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const [value, setValue] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleValueChange = e => {
    setValue({...value, [e.target.name]: e.target.value});
  };

  const handleSave = async () => {
    ajax('Users::SaveMyPassword', value)
      .then(response => {
        if (response.ok)
          props.history.goBack();
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Modificare parola</Typography>
      <Typography color='textSecondary'>Va recomandam sa va schimbati periodic parola</Typography>

      <TextField size='small' variant='standard' fullWidth margin='none' required type='password' inputProps={{maxLength: 64}}
        error={value.oldPassword.length < 6}
        helperText={value.oldPassword.length < 6 ? 'Introduceti parola veche, minim 6 caractere' : ' '}
        name='oldPassword'
        label='Parola veche'
        placeholder='Parola veche'
        value={value.oldPassword}
        onChange={handleValueChange}
      />
      <TextField size='small' variant='standard' fullWidth margin='none' required type='password' inputProps={{maxLength: 64}}
        error={value.newPassword.length < 6}
        helperText={value.newPassword.length < 6 ? 'Introduceti parola noua, minim 6 caractere' : ' '}
        name='newPassword'
        label='Parola noua'
        placeholder='Parola noua'
        value={value.newPassword}
        onChange={handleValueChange}
      />
      <TextField size='small' variant='standard' fullWidth margin='none' required type='password' inputProps={{maxLength: 64}}
        error={value.confirmPassword.length < 6 || value.newPassword !== value.confirmPassword}
        helperText={value.confirmPassword.length < 6 || value.newPassword !== value.confirmPassword ? 'Parola nu corespunde cu cea introdusa anterior' : ' '}
        name='confirmPassword'
        label='Confirmati parola noua'
        placeholder='Confirmati parola noua'
        value={value.confirmPassword}
        onChange={handleValueChange}
      />

      <Link to='/MyAccount' className={classes.link}>
        <Fab color='secondary' className={classes.fabBottomLeft}>
          <ArrowBackIcon />
        </Fab>
      </Link>

      <Fab color='primary' className={classes.fabBottomRight}
        disabled={
          value.oldPassword.length < 6 ||
          value.newPassword.length < 6 ||
          value.confirmPassword.length < 6 || value.newPassword !== value.confirmPassword
        }
        onClick={handleSave}
      >
        <CheckIcon />
      </Fab>
    </Container>
  );
}