import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

export default function MyUsers(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const defaultValue = {
    id: '',
    username: '',
    password: '',
    surname: '',
    name: '',
    cnp: '',
    phone: ''
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, add, edit
    value: null
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.match.params.op) {
      setView({mode: 'add', value: defaultValue});
      setIsLoaded(true);
    }
    ajax('Users::SearchMyUsers')
      .then(response => {
        if (response.ok) {
          setData(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value}});
  };

  const handleSave = async () => {
    ajax('Users::SaveMyUser', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('Users::SearchMyUsers')
            .then(response => {
              if (response.ok) {
                setData(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Utilizatori asociati contului</Typography>
      <Typography color='textSecondary'>Administrare utilizatori asociati acestui cont</Typography>
      <Divider className={classes.divider} />

      {isLoaded && view.mode === 'initial' && data.length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Folositi butonul din dreapta jos pentru a adauga un element.</Typography>
        </Fragment>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              disabled={view.mode === 'edit'}
              error={view.value.username.length < 6}
              helperText={view.value.username.length < 6 ? 'Nume utilizator, min. 6 caractere' : ' '}
              name='username'
              label='Nume de utilizator'
              placeholder='Nume de utilizator'
              value={view.value.username}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off'
              helperText='Completati doar daca doriti schimbarea parolei'
              name='password'
              label='Parola'
              placeholder='Parola'
              value={view.value.password}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.surname.length < 3}
              helperText={view.value.surname.length < 3 ? 'Numele de familie, min 3 caractere' : ' '}
              name='surname'
              label='Nume de familie'
              placeholder='Nume de familie'
              value={view.value.surname}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.name.length < 3}
              helperText={view.value.name.length < 3 ? 'Prenumele, min 3 caractere' : ' '}
              name='name'
              label='Prenume'
              placeholder='Prenume'
              value={view.value.name}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 13}}
              error={!Boolean(view.value.cnp.match(/^\d{13}$/))}
              helperText={!Boolean(view.value.cnp.match(/^\d{13}$/)) ? 'CNP, fix 13 cifre' : ' '}
              name='cnp'
              label='CNP'
              placeholder='CNP'
              value={view.value.cnp}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 10}}
              error={!Boolean(view.value.phone.match(/^07[0-9]{8}$/))}
              helperText={!Boolean(view.value.phone.match(/^07[0-9]{8}$/)) ? 'Numar de telefon, 07xxx' : ' '}
              name='phone'
              label='Numar de telefon'
              placeholder='Numar de telefon'
              value={view.value.phone}
              onChange={handleValueChange}
            />
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {data.map(item =>
            <ListItem key={item.id} dense button divider
              onClick={() => setView({mode: 'edit', value: item})}
            >
              <ListItemText secondaryTypographyProps={{style: {whiteSpace: 'pre-wrap'}}}
                primary={`${item.name} ${item.surname} (${item.phone})`}
                secondary={item.username}
              />
              {item.id !== localStorage.getObject('user').id &&
                <ListItemSecondaryAction>
                  <IconButton edge='end'
                    onClick={() => props.deleteEntity({
                      title: 'Stergere utilizator',
                      question: 'Sunteti sigur ca doriti stergerea acestui utiliator?',
                      content: 'Dupa aceasta operatiune, nu se va mai putea autentifica in aplicatie.',
                      entity: `${item.surname} ${item.name}`,
                      op: 'Users::Delete',
                      id: item.id,
                      history: props.history,
                      pathname: props.location.pathname
                    })}
                  >
                    <DeleteIcon color='secondary' />
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItem>
          )}
        </List>
      }

      {isLoaded && view.mode === 'initial' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'add', value: defaultValue})}
        >
          <AddIcon />
        </Fab>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            view.value.username.length < 6 ||
            view.value.surname.length < 3 ||
            view.value.name.length < 3 ||
            !Boolean(view.value.cnp.match(/^\d{13}$/)) ||
            !Boolean(view.value.phone.match(/^07[0-9]{8}$/))
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}