// export const ajax = async (op, data = {}) => {
//   const sid = localStorage.getObject('user') ? localStorage.getObject('user').sid : null;
//   var formData = new FormData();
//   formData.append('op', op);
//   formData.append('sid', sid);
//   formData.append('params', JSON.stringify(data));
//   const response = await fetch(`http://www.menu-orders.ro/ajax.php?${op}`, {
//     method: 'POST',
//     mode: 'no-cors',
//     cache: 'no-cache',
//     credentials: 'same-origin',
//     redirect: 'follow',
//     referrer: 'no-referrer',
//     body: formData
//   });
//   return await response.json()
//     // .then(data => {
//     //   console.log('AJAX response:', data);
//     //   return data;
//     // })
//     .catch(error => {
//       console.error('There has been a problem with your fetch operation:', error);
//     });
// };

//var userObject = { userId: 24, name: 'Jack Bauer' };
//localStorage.setObject('user', userObject);
Storage.prototype.setObject = function(key, value) {
    this.setItem(key, JSON.stringify(value));
};

//userObject = localStorage.getObject('user');
Storage.prototype.getObject = function(key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
};

// eslint-disable-next-line
String.prototype.toSentenceCase = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// new Date().toTimeString().toHourMinutes() => 'HH:MM'
// eslint-disable-next-line
String.prototype.toHourMinutes = function() {
  return `${this.split(':')[0]}:${this.split(':')[1]}`;
};
// '17:30'.toDateFromHM() => Sun Dec 31 1899 17:30:00 GMT+0144 (Ora standard a Europei de Est)
// eslint-disable-next-line
String.prototype.toDateFromHM = function() {
  return new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), this.split(':')[0], this.split(':')[1]);
};

// new Date().toHHMM()
// eslint-disable-next-line
Date.prototype.toHHMM = function() {
  return `${this.getHours().toString().padStart(2, '0')}:${this.getMinutes().toString().padStart(2, '0')}`;
};

// new Date().timeBetween('09:00', '18:00')
// if stop < start then stop value is tomorow
// eslint-disable-next-line
Date.prototype.timeBetween = function(timeStart, timeStop) {
  const dateStart = timeStart.toDateFromHM();
  const dateStop = timeStart < timeStop
    ? timeStop.toDateFromHM()
    : new Date(timeStop.toDateFromHM().setDate(timeStop.toDateFromHM().getDate() + 1));
    return this.getTime() > dateStart.getTime() && this.getTime() < dateStop.getTime();
};

// new Date().addMonths(12)
// eslint-disable-next-line
Date.prototype.addMonths = function(months) {
  var d = this.getDate();
  this.setMonth(this.getMonth() + +months);
  if (this.getDate() !== d)
    this.setDate(0);
  return this;
};

// await File.toBase64() => 'data:image/jpeg;base64,..............'
File.prototype.toBase64 = function() {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(this);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

// arrOfObjs.getValueById(1, 'specialty')
// return the named property of passed id
// if property name is missing, second key is used
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'getValueById', {
  value: function(id, keyValue = false, keyId = 'id') {
    if (this.length === 0) {
      console.error('Provided list is empty!');
      return null;
    }
    if (keyValue === false)
      keyValue = Object.keys(this[0])[1];
    var filteredArray = this.filter(item => item[keyId] === id);
    if (filteredArray.length === 0) {
      console.error('Provided id was not found!', id, this);
      return null;
    }
    if (filteredArray.length > 1) {
      console.error('Provided id is not unique!', id, this);
      return null;
    }
    if (!filteredArray[0].hasOwnProperty(keyValue)) {
      console.error('Provided property name does not exist in filtered object!', keyValue, filteredArray);
      return null;
    }
    if (!filteredArray[0].hasOwnProperty(keyId)) {
      console.error('Provided property ID does not exist in filtered object!', keyId, filteredArray);
      return null;
    }
    return filteredArray[0][keyValue];
  },
  configurable: true
});
// arrOfObjs.sortByKey('idSpecialty', specialties, 'id', 'specialty')
// sort arrOfObjs by key 'idSpecialty'
// if needed, it looks into 'specialties' array, matching 'idSpecialty' value with 'id' value and compare 'specialty' properties
// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'sortByKey', {
  value: function(key, matches = false, matchValue = false, matchKey = 'id') {
    if (Boolean(matches))
      return this.sort((a, b) => (matches.getValueById(a[key], matchKey, matchValue) > matches.getValueById(b[key], matchKey, matchValue)) ? 1 : ((matches.getValueById(b[key], matchKey, matchValue) > matches.getValueById(a[key], matchKey, matchValue)) ? -1 : 0));
    else
      return this.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
  }
});



export const getLanguage = () => {
  return typeof navigator.languages !== 'undefined' ? navigator.languages[0] : navigator.language;
};

export const getWeekDays = (language = getLanguage()) => {
  var baseDate = new Date(Date.UTC(2020, 2, 2)); // just a Monday
  var weekDays = [];
  for(var i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(language, { weekday: 'long' }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};