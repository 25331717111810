import React, {useState} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  }
}));

export default function ResetPassword(props) {
  const classes = useStyles();
  const [value, setValue] = useState({
    username: '',
    phone: '',
    reCaptcha: false,
    resetToken: '',
    password: ''
  });
  const [view, setView] = useState({
    view: 'phoneStage', // phoneStage, tokenStage, passwordStage
    value: null
  });

  const handleValueChange = e => {
    setValue({...value, [e.target.name]: e.target.value});
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter')
      submitValue();
  };

  const submitValue = async () => {
    if (view.view === 'phoneStage') {
      props.ajax('Users::ResetPassword', value)
        .then(response => {
          if (response.ok) {
            if (response.data === true)
              setView({
                view: 'tokenStage',
                value: null
              });
          }
        });
    }
    if (view.view === 'tokenStage') {
      props.ajax('Users::CheckResetToken', value)
        .then(response => {
          if (response.ok) {
            if (response.data === true)
              setView({
                view: 'passwordStage',
                value: null
              });
          }
        });
    }
    if (view.view === 'passwordStage') {
      props.ajax('Users::ChangePasswordUsingToken', value)
        .then(response => {
          if (response.ok) {
            localStorage.setObject('user', response.data);
            props.setUser(response.data);
          }
        });
    }
  };

  return (
    <Container fixed maxWidth='xs'>
      <CardMedia image='/appLogo_500.png' style={{height: '0px', paddingTop: `${170 / 500 * 100}%`}} />
      <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 64}} disabled={props.isLoading}
        error={value.username.length < 6}
        helperText={value.username.length < 6 ? 'Numele de utilizator, min. 5 caractere' : ' '}
        name='username'
        label='Nume de utilizator'
        value={value.username}
        onKeyPress={handleKeyPress}
        onChange={handleValueChange}
      />
      {view.view === 'phoneStage' &&
        <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 10}} disabled={props.isLoading}
          error={!Boolean(value.phone.match(/^07[0-9]{8}$/))}
          helperText={!Boolean(value.phone.match(/^07[0-9]{8}$/)) ? 'Numar de telefon, 07xxx' : ' '}
          name='phone'
          label='Numar de telefon'
          value={value.phone}
          onKeyPress={handleKeyPress}
          onChange={handleValueChange}
        />
      }
      {!!~['tokenStage', 'passwordStage'].indexOf(view.view) &&
        <TextField fullWidth size='small' variant='outlined' margin='dense' required inputProps={{maxLength: 6}} disabled={props.isLoading}
          error={value.resetToken.length < 6}
          helperText={value.resetToken.length < 6 ? 'Codul primit pe SMS, 6 caractere' : ' '}
          name='resetToken'
          label='Codul primit pe SMS'
          value={value.resetToken}
          onKeyPress={handleKeyPress}
          onChange={handleValueChange}
        />
      }
      {view.view === 'passwordStage' &&
        <TextField fullWidth size='small' variant='outlined' margin='dense' required disabled={props.isLoading}
          error={value.password.length < 6}
          helperText={value.password.length < 6 ? 'Parola noua, min. 6 caractere' : ' '}
          name='password'
          label='Parola noua'
          value={value.password}
          onKeyPress={handleKeyPress}
          onChange={handleValueChange}
        />
      }
      {view.view === 'phoneStage' &&
        <Box>
          <ReCAPTCHA style={{width: '304px', margin: '0px auto 16px'}}
            sitekey={props.reCAPTCHA_siteKey}
            onChange={captcha => setValue({...value, reCaptcha: captcha})}
            onExpired={() => setValue({...value, reCaptcha: false})}
            onErrored={() => setValue({...value, reCaptcha: false})}
          />
          <Box mb={1}>
            <Button size='small'
              onClick={() => setView({view: 'tokenStage', value: null})}
            >
              Am deja codul primit pe SMS
            </Button>
          </Box>
          <Button fullWidth size='small' variant='contained' color='primary'
            disabled={props.isLoading || value.username.length < 6 || !Boolean(value.phone.match(/^07[0-9]{8}$/)) || value.reCaptcha === false}
            onClick={submitValue}
          >
            Resetare parola
          </Button>
        </Box>
      }
      {view.view === 'tokenStage' &&
        <Button fullWidth size='small' variant='contained' color='primary'
          disabled={props.isLoading || value.username.length < 6 || value.resetToken.length < 6}
          onClick={submitValue}
        >
          Verifica codul
        </Button>
      }
      {view.view === 'passwordStage' &&
        <Button fullWidth size='small' variant='contained' color='primary'
          disabled={props.isLoading || value.username.length < 6 || value.password.length < 6}
          onClick={submitValue}
        >
          Schimba parola
        </Button>
      }
      <Box mt={3} style={{display: 'flex', justifyContent: 'space-between'}}>
        <Link to='/Login' className={classes.link}>
          <Button size='small' color='primary' disabled={props.isLoading}>
            Inapoi la autentificare
          </Button>
        </Link>
        <Link to='/Signup' className={classes.link}>
          <Button size='small' disabled={props.isLoading}>
            Creaza cont
          </Button>
        </Link>
      </Box>
    </Container>
  );
}