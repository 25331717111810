import React, {Fragment, useState, useEffect} from 'react';
import 'typeface-roboto';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import GoogleMapReact from 'google-map-react';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import SearchIcon from '@material-ui/icons/Search';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import MessageIcon from '@material-ui/icons/Message';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(1, 0)
  },
  card: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
    margin: '4px'
  },
  cardContent: {
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  cardActions: {
    flexWrap: 'wrap',
    '& > *': {
      margin: `${theme.spacing(.5)}px !important`,
      whiteSpace: 'nowrap'
    }
  },
  link: {
    textDecoration: 'initial',
    color: 'inherit',
    '&:active': {
      color: 'inherit'
    }
  },
  fabBottomLeft: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  fabBottomRight: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  }
}));

const AnyReactComponent = () => <LocationOnIcon color='secondary' fontSize='large' style={{width: '40px', transform: 'translate(-50%, -100%)'}} />;

export default function Companies(props) {
  const classes = useStyles();
  const ajax = props.ajax;
  const user = props.user;
  const defaultValue = {
    id: '',
    name: '',
    rarCode: '',
    headquarterCounty: '',
    headquarterCity: '',
    headquarterAddress: '',
    registerNumber: '',
    taxCode: '',
    workstationCounty: '',
    workstationCity: '',
    workstationAddress: '',
    email: '',
    phone: '',
    description: '',
    latitude: '',
    longitude: '',
    idSmsSender: '',
    isBillable: '',
    lat: '',
    lng: '',
    zoom: '',
    history: ''
  };
  const [isLoaded, setIsLoaded] = useState(false);
  const [view, setView] = useState({
    mode: 'initial', // initial, add, edit
    value: null
  });
  const [companies, setCompanies] = useState([]);
  const [filterCounty, setFilterCounty] = useState('');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (props.match.params.op) {
      setView({mode: 'add', value: defaultValue});
      setIsLoaded(true);
    }
    ajax('Companies::Search')
      .then(response => {
        if (response.ok) {
          setCompanies(response.data);
          setIsLoaded(true);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filterCompanies = company => {
    return false || (
        !!~`${company.name} ${company.rarCode} ${company.workstationCounty} ${company.workstationCity} ${company.workstationAddress}`.toLowerCase().indexOf(filter.toLowerCase()) &&
        (company.workstationCounty === filterCounty)
    );
  };

  const handleValueChange = e => {
    setView({...view, value: {...view.value, [e.target.name]: e.target.value}});
  };

  const handleSave = async () => {
    ajax('Companies::Save', view.value)
      .then(response => {
        if (response.ok) {
          setIsLoaded(false);
          setView({
            mode: 'initial',
            value: null
          });
          ajax('Companies::Search')
            .then(response => {
              if (response.ok) {
                setCompanies(response.data);
                setIsLoaded(true);
              }
            });
        }
      });
  };

  return (
    <Container fixed disableGutters maxWidth='sm'>
      <Typography variant='h5'>Statii ITP</Typography>
      <Typography color='textSecondary'>Administrare unitati autorizate ITP</Typography>
      <Divider className={classes.divider} />

      {isLoaded && companies.length > 0 && view.mode === 'initial' &&
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' required select
              error={filterCounty === ''}
              helperText={filterCounty === '' ? 'Selectati un judet' : ' '}
              label='Filtrare dupa judetul statiei ITP'
              value={filterCounty}
              onChange={e => setFilterCounty(e.target.value)}
            >
              {companies
                .filter((company, i) => companies.findIndex(county => county.workstationCounty === company.workstationCounty) === i/* && company.workstationCounty !== ''*/)
                .sort((a, b) => a.workstationCounty < b.workstationCounty ? -1 : a.workstationCounty > b.workstationCounty ? 1 : 0)
                .map(county =>
                  <MenuItem key={county.workstationCounty} value={county.workstationCounty}>{Boolean(county.workstationCounty) ? county.workstationCounty : 'Fara judet'}</MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              label='Filtrare rapida'
              placeholder='Filtrare rapida'
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' && companies.length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Folositi butonul din dreapta jos pentru a adauga un element.</Typography>
        </Fragment>
      }

      {isLoaded && view.mode === 'initial' && companies.length > 0 && companies.filter(filterCompanies).length === 0 &&
        <Fragment>
          <Typography variant='h6' color='error' align='center'>Lista este goala!</Typography>
          <Typography variant='h6' color='error' align='center'>Filtrele aplicate de dumneavoastra nu au nici un rezultat.</Typography>
        </Fragment>
      }

      {isLoaded && view.mode === 'initial' && filterCounty !== '' && companies.filter(filterCompanies).filter(company => Boolean(company.latitude) && Boolean(company.longitude)).length > 0 &&
        <Fragment>
          <Divider className={classes.divider} />
          <Link to={`/Map/${filterCounty}`} className={classes.link}>
            <Button size='small' variant='contained' color='primary' className={classes.button}
              startIcon={<LocationOnIcon />}
            >
              Vezi pe harta
            </Button>
          </Link>
          <Divider className={classes.divider} />
        </Fragment>
      }

      {!isLoaded &&
        <Typography component='div' align='center'>
          <CircularProgress />
        </Typography>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Grid container spacing={1}>
          {view.mode === 'edit' &&
            <Grid item xs={12}>
              <List>
                <Link to={`CompanyHistory/${view.value.id}`} className={classes.link}>
                  <ListItem dense button divider>
                    <ListItemText primaryTypographyProps={{color: 'textSecondary'}} secondaryTypographyProps={{color: 'primary'}}
                      primary='Istoric'
                      secondary='Click pentru vizualizare/adaugare' />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          }
          <Grid item xs={12} sm={8}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.name.length < 6}
              helperText={view.value.name.length < 6 ? 'Numele societatii, min. 6 caractere' : ' '}
              name='name'
              label='Denumire societate'
              placeholder='Denumire societate'
              value={view.value.name}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ disabled inputProps={{maxLength: 5}}
              error={!Boolean(view.value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/))}
              helperText={!Boolean(view.value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/)) ? 'Numar autorizare RAR' : ' '}
              name='rarCode'
              label='Numar autorizare RAR'
              placeholder='Numar autorizare RAR'
              value={view.value.rarCode}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.headquarterCounty.length < 3}
              helperText={view.value.headquarterCounty.length < 3 ? 'Judet sediu social societate, min. 3 caractere' : ' '}
              name='headquarterCounty'
              label='Judet sediu social'
              placeholder='Judet sediu social'
              value={view.value.headquarterCounty}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.headquarterCity.length < 3}
              helperText={view.value.headquarterCity.length < 3 ? 'Localitate sediu social societate, min. 3 caractere' : ' '}
              name='headquarterCity'
              label='Localitate sediu social'
              placeholder='Localitate sediu social'
              value={view.value.headquarterCity}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 255}}
              error={view.value.headquarterAddress.length < 10}
              helperText={view.value.headquarterAddress.length < 10 ? 'Adresa sediu social societate, min. 10 caractere' : ' '}
              name='headquarterAddress'
              label='Adresa sediu social'
              placeholder='Adresa sediu social'
              value={view.value.headquarterAddress}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 16}}
              // error={view.value.registerNumber.length < 10}
              helperText={view.value.registerNumber.length < 10 ? 'Registrul comertului, min. 10 caractere' : ' '}
              name='registerNumber'
              label='Registrul comertului'
              placeholder='Registrul comertului'
              value={view.value.registerNumber}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 16}}
              // error={view.value.taxCode.length < 2}
              helperText={view.value.taxCode.length < 2 ? 'CUI fiscala, min. 2 caractere' : ' '}
              name='taxCode'
              label='Cod unic de inregistrare fiscala'
              placeholder='Cod unic de inregistrare fiscala'
              value={view.value.taxCode}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.workstationCounty.length < 3}
              helperText={view.value.workstationCounty.length < 3 ? 'Judet statie ITP, min. 3 caractere' : ' '}
              name='workstationCounty'
              label='Judet statie ITP'
              placeholder='Judet statie ITP'
              value={view.value.workstationCounty}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 64}}
              error={view.value.workstationCity.length < 3}
              helperText={view.value.workstationCity.length < 3 ? 'Localitate statie ITP, min. 3 caractere' : ' '}
              name='workstationCity'
              label='Localitate statie ITP'
              placeholder='Localitate statie ITP'
              value={view.value.workstationCity}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' required inputProps={{maxLength: 255}}
              error={view.value.workstationAddress.length < 10}
              helperText={view.value.workstationAddress.length < 10 ? 'Adresa statie ITP, min. 10 caractere' : ' '}
              name='workstationAddress'
              label='Adresa statie ITP'
              placeholder='Adresa statie ITP'
              value={view.value.workstationAddress}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 128}}
              // error={!Boolean(view.value.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i))} //eslint-disable-line
              helperText={!Boolean(view.value.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) ? 'O adresa de email valida' : ' '} //eslint-disable-line
              name='email'
              label='Adresa de email'
              placeholder='Adresa de email'
              value={view.value.email}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ inputProps={{maxLength: 10}}
              // error={!Boolean(view.value.phone.match(/^07[0-9]{8}$/))}
              helperText={!Boolean(view.value.phone.match(/^07[0-9]{8}$/)) ? 'Numar de telefon, fix 10 caractere' : ' '}
              name='phone'
              label='Numar de telefon'
              placeholder='Numar de telefon'
              value={view.value.phone}
              onChange={handleValueChange}
            />
          </Grid>
          {/* <Grid item xs={6} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' disabled
              // error={!Boolean(view.value.latitude.match(/^[0-9]{2}\.[0-9]{6}$/))}
              helperText={!Boolean(view.value.latitude.match(/^[0-9]{2}\.[0-9]{6}$/)) ? 'Latitudine (ex: 12.345678)' : ' '}
              name='latitude'
              label='Latitudine'
              placeholder='Latitudine'
              value={view.value.latitude}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' disabled
              // error={!Boolean(view.value.longitude.match(/^[0-9]{2}\.[0-9]{6}$/))}
              helperText={!Boolean(view.value.longitude.match(/^[0-9]{2}\.[0-9]{6}$/)) ? 'Longitudine (ex: 12.345678)' : ' '}
              name='longitude'
              label='Longitudine'
              placeholder='Longitudine'
              value={view.value.longitude}
              onChange={handleValueChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TextField size='small' variant='standard' fullWidth margin='none' autoComplete='off' /*required*/ disabled
              name='idSmsSender'
              label='Numar SMS sender'
              placeholder='Numar SMS sender'
              value={view.value.idSmsSender}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAA9ee_0ey2zxBJdjPx4klbu_ist7ej_yw' }}
              defaultCenter={{
                lat: +view.value.lat,
                lng: +view.value.lng
              }}
              defaultZoom={+view.value.zoom}
              style={{
                width: '100%',
                height: 0,
                margin: 0,
                  padding: '100% 0px 0px 0px',
                  position: 'relative'
                }}
              options={(maps) => {
                return {
                  mapTypeId: maps.MapTypeId.HYBRID,
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: maps.ControlPosition.TOP_LEFT
                  }
                }
              }}
              onClick={e => {
                setView({...view, value: {...view.value, latitude: e.lat.toFixed(6), longitude: e.lng.toFixed(6)}});
              }}
            >
              <AnyReactComponent
                lat={view.value.latitude}
                lng={view.value.longitude}
                text={view.value.rarCode}
                onClick={() => setView({...view, value: {...view.value, latitude: '', longitude: ''}})}
              />
            </GoogleMapReact>
          </Grid>
        </Grid>
      }

      {isLoaded && view.mode === 'initial' &&
        <List>
          {companies.filter(filterCompanies).map(company =>
            <ListItem key={company.id} dense button divider
              onClick={() => setView({mode: 'edit', value: company})}
            >
              <ListItemIcon>
                {company.isBillable === null &&
                  <SupervisorAccountIcon color='primary' />
                }
                {company.isBillable === '1' && true &&
                  <VerifiedUserIcon color='primary' />
                }
                {company.isBillable === '1' && false &&
                  <MoneyOffIcon color='secondary' />
                }
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant='body2' color='textPrimary'>
                    {Boolean(parseInt(company.history)) &&
                      <MessageIcon fontSize='small' style={{verticalAlign: 'bottom'}} />
                    }
                    {`${company.name} (${company.rarCode})`}
                  </Typography>
                }
                secondary={
                  <Typography variant='body2' color='textSecondary'>
                    {Boolean(company.latitude) && Boolean(company.longitude) &&
                      <LocationOnIcon fontSize='small' color='primary' style={{verticalAlign: 'bottom'}} />
                    }
                    {`${company.workstationAddress}, ${company.workstationCity}, jud. ${company.workstationCounty}`}
                  </Typography>
                }
              />
              {user.id === '1' &&
                <ListItemSecondaryAction>
                  <IconButton edge='end'
                    onClick={() => props.deleteEntity({
                      title: 'Stergere statie ITP',
                      question: 'Sunteti sigur ca doriti stergerea acestei statii ITP?',
                      content: 'Dupa aceasta operatiune, conturile asociate acestei statii ITP nu vor mai avea acces la aplicatii, iar SMS-urile acesteia nu se vor mai trimite.',
                      entity: `${company.name} (${company.workstationAddress}, ${company.workstationCity}, ${company.workstationAddress})`,
                      op: 'Companies::Delete',
                      id: company.id,
                      history: props.history,
                      pathname: props.location.pathname
                    })}
                  >
                    <DeleteIcon color='secondary' />
                  </IconButton>
                </ListItemSecondaryAction>
              }
            </ListItem>
          )}
        </List>
      }

      {isLoaded && view.mode === 'initial' && user.id === '1' &&
        <Fab color='primary' className={classes.fabBottomRight}
          onClick={() => setView({mode: 'add', value: defaultValue})}
        >
          <AddIcon />
        </Fab>
      }

      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='secondary' className={classes.fabBottomLeft}
          onClick={() => setView({mode: 'initial', value: null})}
        >
          <ArrowBackIcon />
        </Fab>
      }
      {isLoaded && !!~['add', 'edit'].indexOf(view.mode) &&
        <Fab color='primary' className={classes.fabBottomRight}
          disabled={
            view.value.name.length < 6 ||
            view.value.headquarterCounty.length < 3 ||
            view.value.headquarterCity.length < 3 ||
            view.value.headquarterAddress.length < 10 ||
            // view.value.registerNumber.length < 10 ||
            // view.value.taxCode.length < 2 ||
            !Boolean(view.value.rarCode.match(/^(B\d{4})|([A-Z]{2}\d{3})$/)) ||
            view.value.workstationCounty.length < 3 ||
            view.value.workstationCity.length < 3 ||
            view.value.workstationAddress.length < 10 ||
            // !Boolean(view.value.email.match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) || //eslint-disable-line
            // !Boolean(view.value.phone.match(/^07[0-9]{8}$/))
            // !Boolean(view.value.latitude.match(/^[0-9]{2}\.[0-9]{6}$/))
            // !Boolean(view.value.longitude.match(/^[0-9]{2}\.[0-9]{6}$/))
            false
          }
          onClick={handleSave}
        >
          <CheckIcon />
        </Fab>
      }
    </Container>
  );
}